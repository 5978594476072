import React, { useCallback, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useLocation} from 'react-router-dom';
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormHelperText from "@mui/material/FormHelperText";
// import Checkbox from "@mui/material/Checkbox";
import { AppContext } from "../Context";

export default function SecondStep() {
  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    variant,
    margin
  } = useContext(AppContext);
  const { address, city, state, zipcode} = formValues;

  const isError = useCallback(
    () =>
      Object.keys({  address, city, state, zipcode }).some(
        (name) =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, address, city, state, zipcode]
  );
  const location = useLocation();
  if(location.state){
    let zipx = location.state.zipcode
    let addressx = location.state.address
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="address"
              name="address"
              placeholder="Enter your address"
              value={address.value || addressx}
              onChange={handleChange}
              error={!!address.error}
              helperText={address.error}
              required={address.required}
            />
          </Grid>
          <Grid item xs={6}>
          <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="city"
              name="city"
              placeholder="Enter your city"
              value={city.value}
              onChange={handleChange}
              error={!!city.error}
              helperText={city.error}
              required={city.required}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="State"
              name="state"
              placeholder="select state"
              value={state.value}
              onChange={handleChange}
              error={!!state.error}
              helperText={state.error}
              required={state.required}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Zip Code"
              name="zipcode"
              placeholder="enter zipcode"
              value={zipcode.value || zipx}
              onChange={handleChange}
              error={!!zipcode.error}
              helperText={zipcode.error}
              required={zipcode.required}
            />
          </Grid>
      
        </Grid>
  
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleBack} sx={{ mr: 45 }}  color="success">
            Previous
          </Button>
          <Button
            variant="contained"
            disabled={isError()}
            color="success"
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </>
    );
  }else{
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="address"
              name="address"
              placeholder="Enter your address"
              value={address.value }
              onChange={handleChange}
              error={!!address.error}
              helperText={address.error}
              required={address.required}
            />
          </Grid>
          <Grid item xs={6}>
          <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="city"
              name="city"
              placeholder="Enter your city"
              value={city.value}
              onChange={handleChange}
              error={!!city.error}
              helperText={city.error}
              required={city.required}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="State"
              name="state"
              placeholder="select state"
              value={state.value}
              onChange={handleChange}
              error={!!state.error}
              helperText={state.error}
              required={state.required}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Zip Code"
              name="zipcode"
              placeholder="enter zipcode"
              value={zipcode.value }
              onChange={handleChange}
              error={!!zipcode.error}
              helperText={zipcode.error}
              required={zipcode.required}
            />
          </Grid>
      
        </Grid>
  
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleBack} sx={{ mr: 45 }}  color="success">
            Previous
          </Button>
          <Button
            variant="contained"
            disabled={isError()}
            color="success"
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </>
    );
  }

}
