import React from "react";
import Layout from "../components/Layout";

export default function NotFountPage() {
  return (
    <Layout>
      <div className="h-[50vh] flex flex-col items-center justify-center">
        <h1 className="font-bold text-3xl text-primary">404</h1>
        <h1 className="font-bold text-3xl text-primary">Page Not Found</h1>
      </div>
    </Layout>
  );
}
