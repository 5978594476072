import React, { useState } from "react";
import MobileNavBar from "./navigation/MobileNavBar";
import NavBar from "./navigation/NavBar";

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <>
      <NavBar onClick={() => setMobileNavOpen((prev) => !prev)} />
      <MobileNavBar
        open={mobileNavOpen}
        onClick={() => setMobileNavOpen(false)}
      />
    </>
  );
};

export default Header;
