import { decode } from "html-entities";
import React from "react";
import { BsCheck, BsClock, BsExclamationTriangle } from "react-icons/bs";
import ImageLightbox from "./ImageLightbox";

export type MsgProps = {
  type: "user" | "guest" | "auto";
  message: string;
  isImageSrc?: string;
  status?: "sent" | "failed" | "sending";
  uuid?: string;
  resend?: (id: string, message: string, imageSrc: boolean) => void;
};

const generateMessage = (message: string) => {
  if (message.startsWith("__ff_trade_closed")) {
    return "Your trade has been marked as closed.";
  }

  if (message.startsWith("__ff_trade_completed")) {
    return "Your trade has been marked as completed, thanks for trading with Spectrum.";
  }

  if (message.startsWith("__ff_admin_joined")) {
    return `You have joined the chat`;
  }
  if (message.startsWith("__ff_trade_opened")) {
    return "Trade opened, an admin is going to join you shortly";
  }
};

const MsgBox = ({
  type,
  message,
  isImageSrc: isImage,
  status = "sent",
  resend,
  uuid,
}: MsgProps) => {
  if (message.startsWith("__ff_") || type === "auto") {
    return (
      <div className="place-self-center text-right max-w-full">
        <div className="text-sm text-gray-500 p-5 rounded-2xl rounded-tr-none flex items-center justify-center">
          <p className="text-sm md:w-2/3 text-center font-semibold">
            {generateMessage(decode(message))}
          </p>
        </div>
      </div>
    );
  }

  if (type === "user") {
    const decodedMessage = decode(message);
    const handleResend = () => {
      if (resend && status === "failed") {
        if (uuid) {
          isImage
            ? resend(uuid, isImage, true)
            : resend(uuid, decodedMessage, false);
        } else {
          console.warn("Message unique ID isn't provided");
        }
      }
    };
    return (
      <div
        className="place-self-end relative text-right max-w-full cursor-pointer"
        onClick={handleResend}
      >
        <div className="bg-purple-700 text-sm text-white p-3 rounded-2xl rounded-tr-none">
          {isImage ? (
            <>
              <ImageLightbox src={isImage} />
              <span className="w-full relative top-[0.375rem] !bottom-auto flex items-center justify-end">
                {status === "sending" ? (
                  <BsClock />
                ) : status === "failed" ? (
                  <BsExclamationTriangle />
                ) : (
                  <BsCheck />
                )}
              </span>
            </>
          ) : (
            <>
              <p className="text-sm text-left">
                {decodedMessage}
                <span className="w-full relative top-[0.375rem] !bottom-auto flex items-center justify-end">
                  {status === "sending" ? (
                    <BsClock />
                  ) : status === "failed" ? (
                    <BsExclamationTriangle />
                  ) : (
                    <BsCheck />
                  )}
                </span>
              </p>
            </>
          )}
        </div>
        {status === "failed" && (
          <i className="text-gray-500 font-medium text-xs">
            not sent, tap here to retry
          </i>
        )}
      </div>
    );
  }

  return (
    <div className="place-self-start max-w-full cursor-pointer">
      <div className="bg-white p-3 rounded-2xl rounded-tl-none">
        {isImage ? (
          <ImageLightbox src={isImage} />
        ) : (
          <>
            <p className="break-words text-sm">{decode(message)}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(MsgBox);
