import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const CTAButton = ({
  className,
  type,
}: {
  className?: string;
  type?: "OUTLINE";
}) => {
  if (type === "OUTLINE") {
    return (
      <Link to="/login">
        <p className="flex justify-center mt-0 text-[12px]">
          Have an account? &nbsp; <p className="text-[#00B776]">Sign in</p>
        </p>
      </Link>
    );
  }
  return (
    <Link
      to="/signup"
      className={classNames(
        "bg-[#00B776] bottom-52 text-sm text-white py-3 px-16 rounded-3xl",
        className
      )}
    >
      Create a new account
    </Link>
  );
};

export default CTAButton;
