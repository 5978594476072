import React from "react";
import SearchBox from "../../components/utility/SearchBox";
import InfoPane from "./InfoPane";
import { BsBell } from "react-icons/bs";
import { useAppSelector } from "../../app/hooks";
import cls from "classnames";
const imgSrc =
  "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png";

const Side = () => {
  return (
    <div className="py-[60px] hidden md:block">
      <div className="flex gap-2">
        <div className="flex items-center justify-start gap-2">
          <img src={imgSrc} className="w-12 rounded-full" alt="" />
          <div className="">
            <h3 className="font-semibold text-sm">Simon John</h3>
            <p className="text-sm text-[#B3B3B3]">Admin</p>
          </div>
          <div className="bg-[#F2F2F2] p-2 rounded-full flex items-center justify-center">
            <BsBell className="text-[16px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

// const sumArray = (arr: Array<number>) => {
//   return arr.reduce((a, b) => a + b, 0);
// };

const Index = () => {
  const { isLoading } = useAppSelector((state) => state.contactInfo);
  const { isLoading: tradeLoading, data: tradeData } = useAppSelector(
    (state) => state.trades
  );
  const { data: cryptoData } = useAppSelector((state) => state.cryptos);
  const fullyLoaded = !(tradeLoading && isLoading);
  // if user is logged in return layout

  return (
    <InfoPane extraInfo={<Side />}>
      <h2>Layout Content</h2>
      {/* Search */}
      <div className="w-full md:w-2/3">
        <SearchBox
          placeholder="Search users, tickets, transactions"
          type="dashboard"
          name="search"
        />
      </div>

      <div className="mt-16">
        <h1>In the last 30 days,</h1>

        <div className="flex flex-wrap items-center gap-6 mt-8">
          <div
            className={cls(
              "bg-orange-500 rounded pt-3 pb-2 text-white space-y-1 pl-3 min-w-[220px]",
              { "skeleton h-[72px]": isLoading || tradeLoading }
            )}
          >
            {fullyLoaded && (
              <>
                <h2 className="text-2xl">{tradeData.length}</h2>
                <p className="text-xs">Trades</p>
              </>
            )}
          </div>
          <div
            className={cls(
              "bg-purple-600 rounded pt-3 pb-2 text-white space-y-1 pl-3 min-w-[220px]",
              { "skeleton h-[72px]": isLoading || tradeLoading }
            )}
          >
            {fullyLoaded && (
              <>
                <h2 className="text-2xl">
                  {tradeData.filter((i) => i.status === "completed").length}
                </h2>
                <p className="text-xs">Completed trades</p>
              </>
            )}
          </div>
          <div
            className={cls(
              "bg-green-600 rounded pt-3 pb-2 text-white space-y-1 pl-3 min-w-[220px]",
              { "skeleton h-[72px]": isLoading || tradeLoading }
            )}
          >
            {fullyLoaded && (
              <>
                <h2 className="text-2xl">
                  {tradeData.filter((i) => i.status === "approved").length}
                </h2>
                <p className="text-xs">Approved trades</p>
              </>
            )}
          </div>
          <div
            className={cls(
              "bg-red-600 rounded pt-3 pb-2 text-white space-y-1 pl-3 min-w-[220px]",
              { "skeleton h-[72px]": isLoading || tradeLoading }
            )}
          >
            {fullyLoaded && (
              <>
                <h2 className="text-2xl">{cryptoData.length}</h2>
                <p className="text-xs">
                  Listed {cryptoData.length > 0 ? "cryptos" : "crypto"}
                </p>
              </>
            )}
          </div>
          <div
            className={cls(
              "bg-sky-600 rounded pt-3 pb-2 text-white  space-y-1 pl-3 min-w-[220px]",
              { "skeleton h-[72px]": isLoading || tradeLoading }
            )}
          >
            {fullyLoaded && (
              <>
                <h2 className="text-2xl">{0}</h2>
                <p className="text-xs">Users</p>
              </>
            )}
          </div>
        </div>

        {/* <div className="flex items-center justify-between mt-8">
          <div className="">
            <h1 className="text-lg font-semibold">All Users</h1>
            <p className="text-xs text-[#B3B3B3]">
              Monitor users, sales, reviews, etc.
            </p>
          </div>
          <div className="flex w-2/4 gap-2">
            <SearchBox
              placeholder="Search users"
              type="dashboard"
              name="search"
            />
            <button className="flex items-center justify-center p-2 gap-1 border border-gray-200">
              <FiFilter />
              <p>filter</p>
            </button>
          </div>
        </div> */}

        {/* {isLoading || tradeLoading ? (
          <div className="my-4 w-full">
            <LoadingPage />
          </div>
        ) : (
          <div className="mt-6">
            <table className="" cellSpacing={10}>
              <thead>
                <tr className="text-left">
                  <th>Users</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data
                  ? data.contacts?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="flex items-center justify-start gap-2">
                            <img
                              src={imgSrc}
                              className="w-12 rounded-full"
                              alt=""
                            />
                            <p>{item.name}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="inline p-1 rounded text-[#427A5B] text-xs bg-[#DEEDE5]">
                              verified
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            <Pagination pages={data ? data.total : 0} />
          </div>
        )} */}
      </div>
    </InfoPane>
  );
};

export default Index;
