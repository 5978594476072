/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
//import { storagePrefix } from "../features/auth/thunkActions";
// import loader from './loader';
import appConfig from "./config";
// eslint-disable-next-line no-shadow

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  ResourceNotFound = 417,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
// const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
//   try {
//     // loader.show();
//     const token: string | null = localStorage.getItem(storagePrefix + "token");

//     if (token) {
//       const parsedToken: string = JSON.parse(token);
//       // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
//       config.headers = {
//         ...config.headers,
//         Authorization: `Bearer ${parsedToken}`,
//       };
//     }
//     return config;
//   } catch (error: any) {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
//     throw new Error(error);
//   }
// };

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const localInstance = axios.create({
      baseURL: appConfig.baseUrl,
      withCredentials: true,
      headers,
    });

    localInstance.defaults.headers.common["Content-Type"] = "application/json";
    localInstance.defaults.headers.common["X-Requested-With"] =
      "XMLHttpRequest";
    localInstance.defaults.headers.common["Accept"] = "application/json";
    localInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "*"; 
    localInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "https://foreignflip.com"; 
   localInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "http://localhost:8070";
   localInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "https://amazingproperties-server.onrender.com";
   localInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "https://spectrum-finance.online";
  //  localInstance.interceptors.request.use(injectToken, (error) =>
  //     Promise.reject(error)
  //   );
    //https://spectrum-finance.online //https://spectrum-0czb.onrender.com

    localInstance.interceptors.response.use(
      (response) => {
        // loader.hide();
        return response.data as AxiosResponse;
      },
      (error: AxiosError) => {
        const responseData = error;
        // loader.hide();
        return this.handleError(responseData);
      }
    );
    this.instance = localInstance;
    return localInstance;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }
  // Handle global app errors
  // We can handle generic app errors depending on the status code
  // eslint-disable-next-line class-methods-use-this

  // eslint-disable-next-line class-methods-use-this
  private handleError(error: AxiosError) {
    switch (error?.response?.status) {
      case StatusCode.Unauthorized: {
        window.location.href = "/login";
        localStorage.clear();
        break;
      }

      default:
        break;
    }
    return Promise.reject(error.response);
  }
}
const http = new Http();
export default http;
