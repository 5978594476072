import { toast, ToastOptions } from "react-toastify";
import Swal from "sweetalert2";

const toastParams: ToastOptions<{}> = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
export const errorToast = (msg: string) => {
  toast.error(msg, toastParams);
};
export const successToast = (msg: string) => {
  toast.success(msg, toastParams);
};
export const warningToast = (msg: string) => {
  toast.warn(msg, toastParams);
};
export const infoToast = (msg: string) => {
  toast.info(msg, toastParams);
};
export const defaultToast = (msg: string) => {
  toast(msg, toastParams);
};

const successData = {
  title: "Trade Completed",
  text: "Thank you for trading with us.",
  icon: "success",
  confirmButtonColor: "#650178",
  confirmButtonText: "OK",
};
const failedData = {
  title: "Failed",
  text: "Your trade didn't complete",
  icon: "error",
  confirmButtonText: "Try again",
};

export const OrderSuccessful = (title?: string, message?: string) => {
  if (title && message) {
    // @ts-ignore
    return Swal.fire({ ...successData, title: title, text: message });
  }
  // @ts-ignore
  return Swal.fire(successData);
};
export const OrderFailed = (title?: string, message?: string) => {
  if (title && message) {
    // @ts-ignore
    return Swal.fire({ ...failedData, title: title, text: message });
  }
  // @ts-ignore
  return Swal.fire(failedData);
};
