/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

interface AppConfig {
  baseUrl?: string;
}

const config: AppConfig = {
  baseUrl: process.env.REACT_APP_BASEURL,
};

export default config;
