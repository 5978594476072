import React, { useEffect } from "react";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { pageVariants } from "../../data/variants";
import Layout from "../../components/Layout";
import {
  getContactsInfo,
  getTrades,
} from "../../features/contacts/thunkActions";
import { useAppDispatch } from "../../app/hooks";
import { errorToast } from "../../app/toasts";
import { getGiftCardTrades } from "../../features/giftCard/thunkActions";

const AdminLayout = () => {
  const dispatch = useAppDispatch();
  const onFailure = (error: any) => {
    console.error(error);
    errorToast("Couldn't fetch Users Data, reload this page to try again");
  };

  useEffect(() => {
    dispatch(getContactsInfo({ onFailure }));
    dispatch(
      getTrades({
        onFailure: (error) => {
          console.error(error);
          errorToast("Couldn't fetch trades, reload this page to try again");
        },
      })
    );
    dispatch(
      getGiftCardTrades({
        onFailure: (error) => {
          console.error(error);
          errorToast(
            "Couldn't fetch Giftcard trades, reload this page to try again"
          );
        },
      })
    );
  }, [dispatch]);

  return (
    <Layout layout="none">
      <motion.div
        variants={pageVariants}
        initial="initial"
        animate="in"
        exit="out"
        className="pt-[60px] md:pt-0"
      >
        <div className="w-full flex">
          <div className="">
            <SideBar />
          </div>
          {/* Content */}
          <Outlet />
        </div>
      </motion.div>
    </Layout>
  );
};

export default AdminLayout;
