import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import Third from "./FourStep";
import Four from "./FiveStep";
import Fift from "./SixStep";
import Confirm from "./Confirm";
import Success from "./Success";
import Grid from "@mui/material/Grid";

import axios from 'axios'
import { successToast } from "../app/toasts";

import { AppContext } from "../Context";

// Step titles
const labels = ["Contact details", "Property details", "Property details", "Upload Property Pictures", "Other Details details"];
const handleSteps = (step: number) => {
  switch (step) {
    case 0:
      return <FirstStep />;
    case 1:
      return <SecondStep />;
    case 2:
      return <Third />;
    case 3:
      return <Four />;
    case 4:
      return <Fift />;
    case 5:
      return <Confirm />;
    default:
      throw new Error("Unknown step");
  }


};

export default function StepForm() {
  const { activeStep, formValues } = useContext(AppContext);

  if (activeStep == 5) {

    setTimeout(() => {
      let name = (formValues.name.value)
      let address = (formValues.address.value)
      let email = (formValues.email.value)
      let state = (formValues.state.value)
      let city = (formValues.city.value)
      let propertytype = (formValues.propertytype.value)
      let zipcode = (formValues.zipcode.value)
      let phone = (formValues.phone.value)
      let bedrooms = (formValues.bedrooms.value)
      let bathroom = (formValues.bathroom.value)
      let footage = (formValues.footage.value)
      let basement = (formValues.basement.value)
      let pool = (formValues.pool.value)
      let floors = (formValues.floors.value)
      let year = (formValues.year.value)
      let uploads = (formValues.uploads.value)
      let whendo = (formValues.whendo.value)
      let mortgage = (formValues.mortgage.value)
      let unpaid = (formValues.unpaid.value)
      let repair = (formValues.repair.value)
      let asking = (formValues.asking.value)
    

      const user = {
        name:name,
        address:address,
        email:email,
        state:state,
        city:city,
        propertytype:propertytype,
        zipcode:zipcode,
        phone:phone,
        bedrooms:bedrooms,
        bathroom:bathroom,
        footage:footage,
        basement:basement,
        pool:pool,
        floors:floors,
        year:year,
        uploads:uploads,
        whendo:whendo,
        mortgage:mortgage,
        unpaid:unpaid,
        repair:repair,
        asking:asking,
      }
      console.log("good", user)
      axios("https://amazingproperties-server.onrender.com /api/v1/amazingproperties/house/create", {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: user,//https://amazingproperties-server.onrender.com 
      })
        .then((response: any) => {
          // const message = response.data.message;http://localhost:8070
          // console.log("hey", message)
          // if (message == "Fill out all required fields") {
          //   errorToast("Please fill out all required fields");
          // }

          // else {
          console.log("))))", response)

          successToast("Successful : Thank you for Choosing Amazing Properties");
          //  }
        })


    }, 1000);
  }

  return (
    <div >

      {activeStep === labels.length ? (
        <Success />

      ) : (
        <>
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center" sx={{ mt: 2, color: "#01A862", fontWeight: 'bold', fontSize: '35px' }}>
              Sell your Property
            </Typography>
            <Typography variant="subtitle2" align="center" sx={{ mt: 2, fontSize: '18px' }}>
              Lets get more acquainted with your property. All you need to do is to fill the details below so we can get you an offer.
            </Typography>
          </Box>
          <Grid container spacing={0} >
            <Grid item xs={12} style={{ margin: 'auto' }}>

              <Stepper activeStep={activeStep} sx={{
                root: {
                  width: '100%',
                  marginRight: 700,
                }, py: 3, color: '#01A862'
              }} alternativeLabel >
                {labels.map((label) => (
                  <Step key={label} sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: '#01A862', // circle color (COMPLETED)
                    },
                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                    {
                      color: 'grey.500', // Just text label (COMPLETED)
                    },
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#01A862', // circle color (ACTIVE)
                    },
                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                    {
                      color: 'common.white', // Just text label (ACTIVE)
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: 'white', // circle's number (ACTIVE)
                    },
                  }}>
                    <StepLabel  >{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {handleSteps(activeStep)}
            </Grid></Grid>





        </>
      )}

    </div>

  );
}
