import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CryptoCurrency } from "../../features/asset/thunkActions";
import CryptoBox from "../CryptoBox";
import LoadingPage from "../LoadingPage";
import AddCrypto from "./AddCrypto";
import EditCrypto from "./EditCrypto";
import cls from "classnames";
import { modalAction } from "../../ts/interfaces/main";
import { errorToast, successToast } from "../../app/toasts";
import http from "../../utils/http";
import Modal from "../PopModal";
import { removeAppCrypto } from "../../features/asset/slice";

const CryptoAsset = () => {
  const { isLoading, data } = useAppSelector((state) => state.cryptos);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [showAddCrypto, setShowAddCrypto] = useState<boolean>(false);
  const [cryptoToDelete, setCryptoToDelete] = useState<
    CryptoCurrency | undefined
  >(undefined);
  const [selectedCrypto, setSelectedCrypto] = useState<CryptoCurrency | null>(
    null
  );
  const handleAction = (type: "edit" | "delete", e: CryptoCurrency) => {
    setSelectedCrypto(e);
    type === "edit" ? setOpenEdit(true) : setOpenDelete(true);
  };

  return (
    <>
      <AnimatePresence>
        {openEdit && selectedCrypto && (
          <EditCrypto
            crypto={selectedCrypto}
            onClose={() => setOpenEdit(false)}
          />
        )}
        {showAddCrypto && (
          // Handle code submit now
          <AddCrypto
            onClose={() => {
              setShowAddCrypto(false);
            }}
          />
        )}
        {openDelete && cryptoToDelete && (
          <DeleteCrypto
            crypto={cryptoToDelete}
            onClose={() => {
              setOpenDelete(false);
              setCryptoToDelete(undefined);
            }}
          />
        )}
      </AnimatePresence>
      <div className="flex flex-wrap space-x-4 items-center">
        <h2 className="font-bold text-[32px]">Cryptocurrencies</h2>
        {/* Add Asset; Types: Crypto Gift card */}
        <button
          onClick={() => setShowAddCrypto(true)}
          className="bg-[#b712d8] hover:bg-primary my-2 text-white py-2 px-4 rounded text-sm md:text-base"
        >
          Add Crypto
        </button>
      </div>
      <div
        className={cls(
          "flex gap-3 my-3 items-center flex-wrap",
          { "gap-x-10": data.length < 3 },
          { "justify-between": data.length >= 3 }
        )}
      >
        {isLoading ? (
          <LoadingPage />
        ) : (
          data &&
          data.map((item) => (
            <CryptoBox
              crypto={item}
              key={item.symbol}
              onEdit={(e) => handleAction("edit", e)}
              onDelete={(e) => {
                setCryptoToDelete(item);
                handleAction("delete", e);
              }}
              editable
              tradeType="both"
            />
          ))
        )}
        {!isLoading && data.length === 0 && (
          <p className="text-gray-600 mt-3">
            No Crypto available, Add a new crypto
          </p>
        )}
      </div>
    </>
  );
};

export default CryptoAsset;

interface deleteNetworkProps extends modalAction {
  crypto: CryptoCurrency;
}
const DeleteCrypto = ({ crypto, onClose }: deleteNetworkProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const deleteNetwork = () => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      dispatch(removeAppCrypto(crypto));
      successToast(`${crypto.name} Deleted`);
      onClose();
    };

    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured, please try again");
    };

    http.get(`/crypto/delete/${crypto.id}`).then(onSuccess).catch(onFailure);
  };
  return (
    <Modal
      title={`Delete Cryptocurrency`}
      innerDivClassName="mt-6"
      onClose={onClose}
    >
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <p className="mb-4">Are you sure you want to delete {crypto.name}?</p>
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="flex space-x-4 items-center justify-end">
            <button
              type="button"
              onClick={onClose}
              className="text-primary bg-transparent py-2 px-4 rounded "
            >
              Cancel
            </button>
            <button
              type={"submit"}
              onClick={deleteNetwork}
              className="bg-primary text-white py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
