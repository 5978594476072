import React, { ReactNode } from "react";
import { Field, ErrorMessage } from "formik";
import classNames from "classnames";

interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  name: string;
  type?: React.HTMLInputTypeAttribute;
  label?: string | ReactNode;
  placeholder: string;
  extraStyles?: string;
  className?: string;
  parentClassName?: string;
  labelClassName?: string;
  icon?: ReactNode;
}
const InputField: React.FC<InputProps> = ({
  name,
  label,
  icon,
  type = "text",
  placeholder,
  extraStyles,
  parentClassName,
  labelClassName,
  className,
  disabled,
  ...otherProps
}) => {
  return (
    <div className={classNames(parentClassName)}>
      <label className={classNames("text-sm font-semibold text-[#00B776] font-bold", labelClassName)}>
        {label}
      </label>
      <div
        className={classNames(
          "inline-flex items-center mt-5 space-x-2 w-full rounded-md focus-within:border-blue-500",
          {
            "bg-white border border-gray-300": !disabled,
            "bg-[#FAFAFA] border-[#FAFAFA]": disabled,
          },
          extraStyles
        )}
      >
        {/* inserting icon before textbox */}
        {icon ? icon : null}
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={classNames(
            "peer focus:outline-none text-sm w-full p-3 text-gray-900 disabled:bg-[#FAFAFA] disabled:text-[#979797] border-[1px]  rounded-lg",
            className
          )}
          {...otherProps}
        />
      </div>
      <ErrorMessage
        name={name}
        render={(err) => <p className="text-red-500 text-sm">{err}</p>}
      />
    </div>
  );
};

export default InputField;
