// interface variants {
//     initial: React.CSSProperties;
//     animate: React.CSSProperties;
//     exit: React.CSSProperties;
// }

const AnimVariants = {
  initial: { width: 0 },
  animate: { width: "100%" },
  exit: { x: -window.innerWidth, transition: { duration: 0.2 } },
};
export const FadeIn = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
export const moveFromTop = {
  initial: {
    opacity: 0,
    y: -200,
    transition: { duration: 0.2 },
  },
  in: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -200,
    transition: { duration: 0.2 },
  },
};
export default AnimVariants;
