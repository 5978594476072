import React from "react";
import classNames from "classnames";
import NavItems from "./NavItems";
import Container from "../utility/Container";
type MobileNavPopupProps = {
  open: boolean;
  onClick: () => void;
};

const MobileNavBar = ({ open }: MobileNavPopupProps) => {
  return (
    <div className="relative">
      <nav
        className={classNames(
          "flex items-start z-20 flex-col w-full py-4 absolute bg-primary text-center lg:hidden",
          { hidden: !open }
        )}
      >
        <Container>
          <NavItems
            showHamburger={false}
            containerClass="py-5 space-y-5 relative text-white text-left"
          />
        </Container>
      </nav>
    </div>
  );
};

export default MobileNavBar;
