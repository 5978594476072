import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { GiftCardTrade, TradeStatus } from "../../state/types";
import { sortByDate } from "../../utils";
import { getGiftCardTrades } from "./thunkActions";

interface GiftcardInitialState {
  isLoading: boolean;
  sort: TradeSortLists;
  showOnly: TradeStatus | "all";
  visibleTrades: GiftCardTrade[];
  data: GiftCardTrade[];
}

const initialGiftCardTradeState: GiftcardInitialState = {
  isLoading: false,
  data: [],
  sort: "latest",
  showOnly: "all",
  visibleTrades: [],
};

type TradeSortLists = "latest" | "oldest";
export const sortGiftCardTrade = createAction<TradeSortLists>(
  "trades/giftCard/sort"
);
export const hideGiftCardTradeExcept = createAction<TradeStatus | "all">(
  "trades/giftCard/showonly"
);

export const GiftCardTradeSlice = createSlice({
  name: "giftCardTrades",
  initialState: initialGiftCardTradeState,
  reducers: {
    addGiftCardTrade: {
      reducer: (state, action: PayloadAction<GiftCardTrade>) => {
        const trade = action.payload;
        const rest = state.data.filter((t) => t.id !== trade.id);
        const restV = state.visibleTrades.filter((t) => t.id !== trade.id);
        state.data = [trade, ...rest];
        state.visibleTrades = [trade, ...restV];
      },
      prepare: (trade: GiftCardTrade) => {
        return { payload: trade };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGiftCardTrades.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGiftCardTrades.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getGiftCardTrades.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = sortByDate(
          action.payload || initialGiftCardTradeState.data,
          state.sort
        );
        if (state.showOnly === "all") {
          state.visibleTrades = state.data;
        } else {
          state.visibleTrades = state.data.filter(
            (d) => d.status === state.showOnly
          );
        }
      })
      .addCase(sortGiftCardTrade, (state, action) => {
        const by = action.payload;
        state.visibleTrades = sortByDate(state.visibleTrades, by);
        state.sort = by;
      })
      .addCase(hideGiftCardTradeExcept, (state, action) => {
        const only = action.payload;
        if (only === "all") {
          state.visibleTrades = state.data;
          state.showOnly = only;
        } else {
          state.visibleTrades = state.data.filter((d) => d.status === only);
          state.showOnly = only;
        }
      });
  },
});

export const giftCardTrades = GiftCardTradeSlice.reducer;
