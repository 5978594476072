import React, { Fragment, useCallback, useEffect } from "react";
import Header from "./Header";
import Footer from "./Home/Footer";

type LayoutProps = {
  children: React.ReactNode;

  layout?: "header" | "footer" | "headerAndFooter" | "none";
};

function Layout({ layout = "headerAndFooter", children }: LayoutProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderFunction = useCallback(() => {
    if (layout === "none") {
      return <Fragment>{children}</Fragment>;
    } else if (layout === "headerAndFooter") {
      return (
        <Fragment>
          <Header />
          {children}
          <Footer />
        </Fragment>
      );
    } else if (layout === "footer") {
      return (
        <Fragment>
          {children}
          <Footer />
        </Fragment>
      );
    } else if (layout === "header") {
      return (
        <Fragment>
          <Header />
          {children}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Header />
          {children}
          <Footer />
        </Fragment>
      );
    }
  }, [layout, children]);

  return renderFunction();
}

export default Layout;
