import React, { Fragment, useState } from "react";
import UserList, { Contact } from "../../components/Admin/UserList";
import AdminChatSection from "../AdminChatSection";
import InfoPane from "./InfoPane";

const AdminChat = () => {
  const [currentContact, setCurrentContact] = useState<Contact | undefined>(
    undefined
  );
  return (
    <InfoPane>
      <div className="w-full flex flex-col md:flex-row">
        <div className="w-full md:w-[40%] ">
          <UserList
            selectedContact={currentContact}
            setSelectedContact={setCurrentContact}
          />
        </div>
        <div className="w-screen md:w-[60%]">
          {typeof currentContact === "undefined" ? (
            <div className="h-screen w-full flex items-center justify-center">
              <p className="text-center">Select a contact</p>
            </div>
          ) : (
            <Fragment>
              <AdminChatSection UserContact={currentContact} />
            </Fragment>
          )}
        </div>
      </div>
    </InfoPane>
  );
};

export default AdminChat;
