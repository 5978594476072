import React, {
  useCallback,
  useContext
} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AppContext } from "../Context";
import { useLocation } from 'react-router-dom';


export default function FirstStep() {
  const { formValues, handleChange, handleNext, variant, margin } = useContext(
    AppContext
  );
  const { name, phone, email } = formValues;


  const isError = useCallback(
    () =>
      Object.keys({ name, phone, email }).some(
        (name) => (
          (formValues[name].required && !formValues[name].value)
        ) || formValues[name].error
      ),
    [formValues, name, phone, email]
  );


  const location = useLocation();
  if (location.state) {
    let fullname = location.state.fullname || "";
    let emailx = location.state.email || ""
    let phonex = location.state.phone || ""

    return (
      <>
        <Grid container spacing={12} >
          <Grid item xs={12} sm={12}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Name"
              name="name"
              placeholder="john carter"
              //defaultValue={name.value || fullname}
              value={name.value || fullname}

              onChange={handleChange}
              error={!!name.error}
              helperText={name.error}
            // required={name.required}



            />
          </Grid>


          <Grid item xs={12} sm={6}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Email"
              name="email"
              placeholder="Your email address"
              type="email"
              //defaultValue={email.value || emailx}
              value={email.value || emailx}
              onChange={handleChange}
              error={!!email.error}
              helperText={email.error}
            // required={email.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Phone Number"
              name="phone"
              placeholder="Your Phone Number"
              type="tel"
              //defaultValue={phone.value || phonex}
              value={phone.value || phonex}
              onChange={handleChange}
              error={!!phone.error}
              helperText={phone.error}
            // required={phone.required}

            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            //disabled={isError()}
            color="success"
            onClick={ handleNext }
            // onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </>
    );
  } else {

    return (
      <>
        <Grid container spacing={12} >
          <Grid item xs={12} sm={12}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Name"
              name="name"
              placeholder="john carter"
              //defaultValue={name.value || fullname}
              value={name.value}

              onChange={handleChange}
              error={!!name.error}
              helperText={name.error}
              required={name.required}



            />
          </Grid>


          <Grid item xs={12} sm={6}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Email"
              name="email"
              placeholder="Your email address"
              type="email"
              //defaultValue={email.value || emailx}
              value={email.value}
              onChange={handleChange}
              error={!!email.error}
              helperText={email.error}
              required={email.required}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant={variant}
              margin={margin}
              fullWidth
              label="Phone Number"
              name="phone"
              placeholder="Your Phone Number"
              type="tel"
              //defaultValue={phone.value || phonex}
              value={phone.value}
              onChange={handleChange}
              error={!!phone.error}
              helperText={phone.error}
              required={phone.required}

            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            disabled={isError()}
            color="success"
            //onClick={ handleNext }
            onClick={!isError() ? handleNext : () => null}
          >
            Next
          </Button>
        </Box>
      </>
    );
  }

}
