import { useState, useRef, useEffect } from 'react';
import Container from "../components/utility/Container";
import { testmomies } from "../data";
import { ChevronLeft, ChevronRight } from "react-feather";


const Carousel = () => {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="carousel my-5 mx-auto">
   
      <div className="relative overflow-hidden">
        <div className="flex justify-between absolute top left inset-0  md:-top-5  items-center">
          <button
            onClick={movePrev}
            className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776] disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled('prev')}
          >
           <ChevronLeft size={30} color="#fff"  />
          </button>
          <button
            onClick={moveNext}
            className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776] disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled('next')}
          >
         <ChevronRight size={30} color="#fff"  />
          </button>
        </div>
        <div
          ref={carousel}
        
          className="carousel-container relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
        >
          
                  {testmomies.map((item, index) => (
                <>

                  <div key={index} 
                  style={{ height: '400px'}}
                   className="carousel-item text-center relative w-84 h-84  snap-start"
                  //className="w-full  flex justify-center items-start lg:self-start lg:justify-self-end relative lg:-right-[20px] flex-1"
                  >
                    {/* bg-gradient-to-br from-[#ffffff] to-[#ffffff] */}
                    <div 
                    className="w-full  flex justify-center items-start lg:self-start lg:justify-self-end relative lg:-right-[20px] flex-1"
                    >
                    {/* 

                      <div className=" md:ml-20 md:mr-20 ml-3 mr-3 -mt-16 w-[100%]  "> rounded-xl md:p-16 p-4 pt-5*/}

                        <div style={{ marginTop:'50px', }} className="flex flex-col lg:flex-row pl-6 pr-6  justify-between shadow-lg rounded-xl border-[1px] hover:border-primary mx-3 ">

                          <div className="flex-1 lg:flex items-center justify-center w-[320px]">

                            <Container className="flex items-center justify-center " style={{ height: '300px' }}>
                              <div className="flex-1 space-y-2">

                                <h1 className="font-bold items-center w-full text-[14px]" style={{ color: '#00B776', marginTop:'20px', textAlign:'center', display:'inline-flex'}}>
                                  “{item.title}”
                                </h1>
                                <p className='w-full text-sm   text-[#3A3A3A] ' style={{ fontFamily: 'inherit', textAlign:'center',textWrap:'wrap' }}>
                                  {item.message}
                                </p>
                                <div className="flex flex-col lg:flex-row-reverse items-center justify-center space-y-3">
<br/>
                                  <div className='lg:flex-[1.0] flex-row-reverse items-center justify-center'>

                                    <div>  <h5 className="text-left items-right font-bold" style={{ color: '#00B776', fontSize:'15px'}}>
                                      {item.fullname}
                                    </h5></div>

                                    <div> <p style={{ color: '#000', textAlign: 'left', fontStyle: 'italic' }}>
                                      {item.location}
                                    </p></div>



                                  </div>

                                </div>

                              </div>
                            </Container>
                          </div>


                        {/* </div>
                     */}
                      </div> 
                    </div>
             </div>

                </>
              ))}
    
        </div>
      </div>
    </div>
  );
};

export default Carousel;