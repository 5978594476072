import React from 'react'



const CardsSvg = ({ className }: { className?: string }) => {
    return (
        <svg className={className} width="66" height="51" viewBox="0 0 66 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.87551 14.0713L53.9453 0.0870904C55.2947 -0.275447 56.6814 0.526951 57.0431 1.87949L65.3197 32.8402C65.6808 34.1927 64.8809 35.5827 63.5315 35.9446L11.4611 49.9294C10.9135 50.0765 10.3596 50.0315 9.87077 49.8356C9.15497 49.5492 8.57828 48.9401 8.36328 48.137L0.0866272 17.1763C-0.274436 15.8238 0.526101 14.4339 1.87551 14.0713Z" fill="#7B0591" />
            <path d="M23.3642 18.5422C30.0777 11.2927 40.2211 5.22407 53.7942 0.336171C55.1088 -0.136648 56.5575 0.547228 57.0298 1.86554C57.0532 1.93145 57.0747 1.998 57.0931 2.06646L65.3198 32.8402C65.6808 34.1927 64.8803 35.5826 63.5309 35.9452L10.9154 50.0758C10.9154 50.0758 10.4551 50.1703 9.97197 49.9801C9.48886 49.79 9.18028 49.6962 9.20747 49.553C11.6913 36.3882 16.4111 26.0509 23.3642 18.5422Z" fill="#F7931A" />
            <path d="M9.97571 30.2923L30.4381 24.7972C31.0281 24.6388 31.6351 24.9899 31.7932 25.5812C31.9513 26.1732 31.601 26.7804 31.011 26.9389L10.5486 32.4346C9.95863 32.593 9.35159 32.2419 9.1935 31.6506C9.03542 31.0586 9.3851 30.4508 9.97571 30.2923Z" fill="white" />
            <path d="M11.285 35.189L25.6384 31.3342C26.229 31.1758 26.8361 31.5269 26.9941 32.1183C27.1529 32.7102 26.8025 33.3187 26.2119 33.4771L11.8585 37.3326C11.2679 37.491 10.6609 37.1399 10.5028 36.5485C10.3441 35.9566 10.6944 35.3475 11.285 35.189Z" fill="white" />
            <path d="M52.5257 29.0589L58.0219 27.5815C58.3596 27.4909 58.7061 27.6918 58.7972 28.0296L59.6154 31.0915C59.7052 31.4293 59.5054 31.7773 59.1684 31.8679L53.6715 33.3441C53.3338 33.4347 52.986 33.2338 52.8962 32.896L52.0786 29.834C51.9875 29.4962 52.188 29.1495 52.5257 29.0589Z" fill="white" />
            <path d="M11.3974 16.3645C12.7835 15.9924 14.2082 16.8164 14.5794 18.2057C14.9505 19.595 14.1285 21.0229 12.7424 21.395C12.0962 21.5686 11.4417 21.4812 10.889 21.1985C10.5533 21.7201 10.0291 22.1238 9.38281 22.2969C7.99737 22.6695 6.57271 21.8443 6.2009 20.4557C5.82972 19.0664 6.65239 17.6384 8.03847 17.2664C8.68408 17.0927 9.33918 17.1795 9.89121 17.4616C10.2276 16.9406 10.7512 16.5381 11.3974 16.3645Z" fill="white" />
            <path d="M6.56743 13.8768H60.4741C61.871 13.8768 63.0035 15.012 63.0035 16.412V48.4648C63.0035 49.8655 61.871 51 60.4741 51H6.56743C5.1706 51 4.03809 49.8655 4.03809 48.4648V16.412C4.03809 15.012 5.1706 13.8768 6.56743 13.8768Z" fill="#FCC645" />
            <path d="M26.3084 23.6704C34.6654 18.4092 46.0297 15.1793 60.4028 13.9795C61.7945 13.8635 63.0175 14.9004 63.1332 16.2954C63.1389 16.3658 63.142 16.4355 63.142 16.5059V48.3653C63.142 49.766 62.0095 50.9005 60.6127 50.9005H6.02749C6.02749 50.9005 5.68603 50.8942 5.24403 50.5963C4.80203 50.2984 4.54783 50.1146 4.61801 49.9688C10.4184 37.8891 17.6492 29.1217 26.3084 23.6704Z" fill="#A562BA" />
            <path d="M10.2035 31.6461H31.3868C31.9976 31.6461 32.4934 32.1424 32.4934 32.7553C32.4934 33.3676 31.9976 33.8645 31.3868 33.8645H10.2035C9.59268 33.8645 9.09692 33.3676 9.09692 32.7553C9.09692 32.1424 9.59268 31.6461 10.2035 31.6461Z" fill="white" />
            <path d="M10.2035 36.7166H25.0634C25.6743 36.7166 26.17 37.2129 26.17 37.8257C26.17 38.438 25.6743 38.9349 25.0634 38.9349H10.2035C9.59268 38.9349 9.09692 38.438 9.09692 37.8257C9.09692 37.2129 9.59268 36.7166 10.2035 36.7166Z" fill="white" />
            <path d="M51.6214 41.4923H57.3124C57.6621 41.4923 57.9447 41.7763 57.9447 42.1261V45.2951C57.9447 45.6456 57.6621 45.9289 57.3124 45.9289H51.6214C51.2717 45.9289 50.989 45.6456 50.989 45.2951V42.1261C50.989 41.7763 51.2717 41.4923 51.6214 41.4923Z" fill="white" />
            <path d="M15.1724 18.5613C16.6072 18.5613 17.7701 19.7268 17.7701 21.1649C17.7701 22.603 16.6072 23.7699 15.1724 23.7699C14.5034 23.7699 13.8938 23.5157 13.4329 23.0993C12.9731 23.5157 12.3636 23.7699 11.6946 23.7699C10.2598 23.7699 9.09692 22.603 9.09692 21.1649C9.09692 19.7268 10.2598 18.5613 11.6946 18.5613C12.3636 18.5613 12.9731 18.8154 13.4341 19.2318C13.8938 18.8154 14.5034 18.5613 15.1724 18.5613Z" fill="white" />
        </svg>

    )
}

export default CardsSvg