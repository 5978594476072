import { createSlice } from "@reduxjs/toolkit";
import { loginAdmin, logoutAdmin, User } from "./thunkActions";

interface UserInitialState {
    isLoading: boolean;
    data: User | null;
  }

const userInitialState : UserInitialState = {
    isLoading: false,
    data: null
}
export const userSlice = createSlice({
    name: "user",
    initialState: userInitialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(loginAdmin.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(loginAdmin.rejected, (state) => {
          state.isLoading = false;
        })
        .addCase(loginAdmin.fulfilled, (state, action) => {
          state.isLoading = false;
          state.data = action.payload || null;
        })
        .addCase(logoutAdmin.fulfilled, (state) => {
          state.isLoading = false;
          state.data=null;
        });
    },
  });

export const userAuth = userSlice.reducer