import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import LoadingPage from "../LoadingPage";
import Container from "../utility/Container";

// type rateProps = {
//     symbol: string;
//     price: number;
// }
// const demoRates: currencyRates[] = [
//   {
//     name: "Bitcoin",
//     symbol: "btc",
//     currentPrice: 9964030,
//     sellingPrice: 9994500,
//     buyingPrice: 9954500,
//   },
//   {
//     name: "Ethereum",
//     symbol: "eth",
//     currentPrice: 481093,
//     sellingPrice: 492309,
//     buyingPrice: 463093,
//     symbolImgSrc:
//       "https://dynamic-assets.coinbase.com/dbb4b4983bde81309ddab83eb598358eb44375b930b94687ebe38bc22e52c3b2125258ffb8477a5ef22e33d6bd72e32a506c391caa13af64c00e46613c3e5806/asset_icons/4113b082d21cc5fab17fc8f2d19fb996165bcce635e6900f7fc2d57c4ef33ae9.png",
//   },
//   {
//     name: "Tether",
//     symbol: "usdt",
//     currentPrice: 620,
//     sellingPrice: 650,
//     buyingPrice: 610,
//     symbolImgSrc: "https://cryptologos.cc/logos/tether-usdt-logo.svg",
//   },
// ];

export const btcImgSrc = "https://www.svgrepo.com/show/210231/coin.svg";
const Trade = () => {
  const { isLoading, data } = useAppSelector((state) => state.cryptos);

  return (
    <section className="py-[60px]">
      <Container className="">
        <h4 className="text-center mb-1 text-gray-700 capitalize">
          Never sell for less
        </h4>
        <h1 className="text-center text-[38px] md:text-[48px] mb-3 text-primary font-bold capitalize">
          Trade In No Time
        </h1>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                {isLoading ? (
                  <LoadingPage />
                ) : (
                  <>
                    <table className="hidden min-w-full md:table">
                      <thead className="bg-white border-b">
                        <tr>
                          <th
                            scope="col"
                            className="text-lg font-semibold text-black px-6 py-4 text-left"
                          >
                            Crypto
                          </th>

                          <th
                            scope="col"
                            className="hidden md:table-cell text-lg font-semibold text-black px-6 py-4 text-left"
                          >
                            Selling Price
                          </th>
                          <th
                            scope="col"
                            className="hidden md:table-cell text-lg font-semibold text-black px-6 py-4 text-left"
                          >
                            Buying Price
                          </th>
                          <th
                            scope="col"
                            className="hidden md:table-cell text-lg font-semibold text-black px-6 py-4 text-left"
                          >
                            Trade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr className="" key={index}>
                            <td className="py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              <div className="flex space-x-2 items-center justify-start">
                                <img
                                  src={item.image_url || btcImgSrc}
                                  className="max-w-[26px] max-h-[26px]"
                                  alt="bitcoin symbol"
                                />
                                <div className="">
                                  <h4 className="text-[13px] font-semibold">
                                    {item.name}
                                  </h4>
                                  <h5 className="text-[10px] font-semibold">
                                    {item.symbol.toUpperCase()}
                                  </h5>
                                </div>
                              </div>
                            </td>

                            <td className="hidden md:table-cell text-red-600 font-semibold px-6 py-4 whitespace-nowrap">
                              ₦{item.sell_rate.toLocaleString()}
                            </td>
                            <td className="hidden md:table-cell text-[#0FA958] font-semibold px-6 py-4 whitespace-nowrap">
                              ₦{item.buy_rate.toLocaleString()}
                            </td>
                            <td className="hidden md:table-cell text-gray-900 space-x-3 font-light py-4 whitespace-nowrap">
                              <Link
                                to={`/crypto/buy?symbol=${item.symbol}`}
                                className="inline-block bg-[#0FA958] hover:bg-[#0f9c51] border-0 font-medium px-8 py-2 text-white"
                              >
                                Buy
                              </Link>
                              <Link
                                to={`/crypto/sell?symbol=${item.symbol}`}
                                className="inline-block bg-red-600 hover:bg-red-700 border-0 font-medium px-8 py-2 text-white"
                              >
                                Sell
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <table className="min-w-full md:hidden">
                      <thead className="bg-white border-b">
                        <tr>
                          <th
                            scope="col"
                            className="text-lg font-semibold text-black px-6 py-4 text-left"
                          >
                            Crypto
                          </th>
                          <th
                            scope="col"
                            className="text-lg font-semibold text-black px-6 py-4 text-left"
                          >
                            Trade
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr className="" key={index}>
                            <td className="py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              <div className="flex space-x-2 items-center justify-start">
                                <img
                                  src={item.image_url || btcImgSrc}
                                  className="max-w-[26px] max-h-[26px]"
                                  alt="bitcoin symbol"
                                />
                                <div className="">
                                  <h4 className="text-[13px] font-semibold">
                                    {item.name}
                                  </h4>
                                  <h5 className="text-[10px] font-semibold">
                                    {item.symbol.toUpperCase()}
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td className="text-gray-900 space-x-3 font-light py-4 whitespace-nowrap">
                              <Link
                                to={`/crypto/buy?symbol=${item.symbol}`}
                                className="inline-block bg-[#0FA958] hover:bg-[#0f9c51] border-0 font-medium px-8 py-2 text-white"
                              >
                                Buy
                              </Link>
                              <Link
                                to={`/crypto/sell?symbol=${item.symbol}`}
                                className="inline-block bg-red-600 hover:bg-red-700 border-0 font-medium px-8 py-2 text-white"
                              >
                                Sell
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Trade;
