import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cryptoSlice, {
  currencyReducer,
  giftcardReducer,
  networksReducer,
} from "../features/asset/slice";
import { userAuth } from "../features/auth/slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  contactsInfo,
  currentTradeReducer,
  trades,
} from "../features/contacts/slice";
import { giftCardTrades } from "../features/giftCard/slice";

const reducers = combineReducers({
  cryptos: cryptoSlice,
  networks: networksReducer,
  user: userAuth,
  contactInfo: contactsInfo,
  trades: trades,
  giftCardTrades: giftCardTrades,
  currentTrade: currentTradeReducer,
  currencies: currencyReducer,
  giftcards: giftcardReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "networks",
    "contactsInfo",
    "trades",
    "giftCardTrades",
    "currentTrade",
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
