import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CurrencyValue } from "../../features/asset/thunkActions";
import LoadingPage from "../LoadingPage";
import cls from "classnames";
import { AnimatePresence } from "framer-motion";
import AddCurrency from "./AddCurrency";
import Modal from "../PopModal";
import http from "../../utils/http";
import { errorToast, successToast } from "../../app/toasts";
import { modalAction } from "../../ts/interfaces/main";
import { removeAppCurrency } from "../../features/asset/slice";

const Currency = () => {
  const [openAddCurrency, setOpenAddCurrency] = useState<boolean>(false);
  const [currency, setCurrency] = useState<CurrencyValue | undefined>(
    undefined
  );
  const { loading, data } = useAppSelector((state) => state.currencies);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [currencyToDelete, setCurrencyToDelete] = useState<
    CurrencyValue | undefined
  >(undefined);

  return (
    <>
      <AnimatePresence>
        {openAddCurrency && (
          <AddCurrency
            currency={currency}
            onClose={() => {
              setCurrency(undefined);
              setOpenAddCurrency(false);
              // add network to store
            }}
          />
        )}
        {showDelete && currencyToDelete && (
          <DeleteCurrency
            currency={currencyToDelete}
            onClose={() => {
              setCurrencyToDelete(undefined);
              setShowDelete(false);
              // remove network from store
            }}
          />
        )}
      </AnimatePresence>
      <div className="flex flex-wrap space-x-4 items-center">
        <h2 className="font-bold text-[32px]">Currencies</h2>
        <button
          onClick={() => setOpenAddCurrency(true)}
          className="bg-[#b712d8] hover:bg-primary my-2 text-white py-2 px-4 rounded text-sm md:text-base"
        >
          Add Currency
        </button>
      </div>
      <div
        className={cls(
          "flex gap-3 my-3 items-center flex-wrap",
          { "gap-x-10": data.length < 3 },
          { "justify-between": data.length >= 3 }
        )}
      >
        {loading ? (
          <LoadingPage />
        ) : (
          data &&
          data.map((item) => (
            <div
              key={item.id.toString()}
              className="px-5 py-3 w-full md:w-2/5 flex items-center justify-between border border-gray-200"
            >
              <div className="flex items-baseline gap-2">
                <p className="text-sm font-semibold">{item.name}</p>
                <p className="text-xs font-semibold text-gray-300">
                  {item.symbol} {item.base_rate}
                </p>
              </div>
              <div className="flex gap-2">
                <MdEdit
                  onClick={() => {
                    setCurrency(item);
                    setOpenAddCurrency(true);
                  }}
                  className="cursor-pointer text-2xl text-black hover:scale-105 ease-in duration-100"
                />
                <MdDelete
                  onClick={() => {
                    setCurrencyToDelete(item);
                    setShowDelete(true);
                  }}
                  className="cursor-pointer text-2xl text-black hover:scale-105 ease-in duration-100"
                />
              </div>
            </div>
          ))
        )}
        {!loading && data.length === 0 && (
          <p className="text-gray-600 mt-3">
            No Currency available, Add a new Currency
          </p>
        )}
      </div>
    </>
  );
};

export default Currency;

interface deleteCurrencyProps extends modalAction {
  currency: CurrencyValue;
}
const DeleteCurrency = ({ currency, onClose }: deleteCurrencyProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const deleteCurrency = () => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      dispatch(removeAppCurrency(currency));
      successToast(`${currency.name} Deleted"`);
      onClose();
    };

    const onFailure = (err: any) => {
      setLoading(false);
      console.error(err);
      errorToast("An error occured, please try again");
    };

    http
      .get(`/currency/delete/${currency.id}`)
      .then(onSuccess)
      .catch(onFailure);
  };
  return (
    <Modal
      title={currency ? "Edit Currency" : `Add New Currency`}
      innerDivClassName="mt-6"
      onClose={onClose}
    >
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <p className="mb-4">Are you sure you want to delete {currency.name}?</p>
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="flex space-x-4 items-center justify-end">
            <button
              type="button"
              onClick={onClose}
              className="text-primary bg-transparent py-2 px-4 rounded "
            >
              Cancel
            </button>
            <button
              type={"submit"}
              onClick={deleteCurrency}
              className="bg-primary text-white py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
