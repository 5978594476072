import React, { useState } from "react";
import MobileNavBar from "./navigation/MobileNavBar";
import AccountNavBar from "./navigation/AccountNavBar";

const AccountHeader = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <>
      <AccountNavBar onClick={() => setMobileNavOpen((prev) => !prev)} />
      <MobileNavBar
        open={mobileNavOpen}
        onClick={() => setMobileNavOpen(false)}
      />
    </>
  );
};

export default AccountHeader;
