import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReduxAction } from "../../ts/interfaces/redux";
import http from "../../utils/http";

export const getContactsInfo = createAsyncThunk(
  "admin/contacts",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const data: any = await http.get("/getContacts");
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const getTrades = createAsyncThunk(
  "admin/trades",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const data: any = await http.get("/trades");
      if (onSuccess) onSuccess(data.trades);
      return data.trades;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
