import { ValidationSchema } from './Context'


export const initialValues: ValidationSchema = {
  
  name: {
    value: "",
    error: '',
    // required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 200,
    helperText: 'Custom error message'
  },
  address: {
    value: "",
    error: '',
    required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  email: {
    value: "",
    error: '',
    validate: 'email',
    // required: true,
  },
  state: {
    value: '',
    error: '',
    validate: 'select'
  },
  city: {
    value: '',
    error: '',
    validate: 'select'
  },
  propertytype: {
    value: '',
    error: '',
    validate: 'select'
  },
  date: {
    value: '',
    error: ''
  },
  zipcode: {
    value: '',
    error: '',
    // validate: 'text',
    minLength: 3,
    maxLength: 200
  },
  phone: {
    value: '',
    error: '',
    validate: 'phone',
    maxLength: 15,
    required: true,
  },
  bedrooms: {
    value: '',
    error: '',
    // required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  bathroom: {
    value: '',
    error: '',
    // required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  footage: {
    value: '',
    error: '',
    // required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  basement: {
    value: '',
    error: '',
    // required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },

  pool: {
    value: '',
    error: '',
    // required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  floors: {
    value: '',
    error: '',
    // required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  year: {
    value: '',
    error: '',
    //required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  uploads: {
    value: '',
    error: '',
    // validate: 'text',
    // minLength: 2,
    // maxLength: 20
  },
  whendo: {
    value: '',
    error: '',
    // required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  mortgage: {
    value: '',
    error: '',
    // required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  unpaid: {
    value: '',
    error: '',
    // required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  repair: {
    value: '',
    error: '',
    // required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },
  asking: {
    value: '',
    error: '',
    required: true,
    // validate: 'text',
    minLength: 2,
    maxLength: 200
  },

}



