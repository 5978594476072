import { useEffect } from "react";

import { useAppDispatch } from "../app/hooks";
import {
  getAllCurrencies,
  getAllGiftcards,
  getAllNetworks,
  showAllCrpyto,
} from "../features/asset/thunkActions";

const Initializer = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onFailure = (error: string) => {
      console.error(error);
    };
    dispatch(showAllCrpyto({ onFailure }));
    dispatch(getAllNetworks({ onFailure }));
    dispatch(getAllCurrencies({ onFailure }));
    dispatch(getAllGiftcards({ onFailure }));
    window.scrollTo(0, 0);
  }, [dispatch]);

  return null;
};

export default Initializer;
