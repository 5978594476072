import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contact } from "../../components/Admin/UserList";
import { Trade, TradeStatus } from "../../state/types";
import { sortByDate } from "../../utils";
import { getContactsInfo, getTrades } from "./thunkActions";

export interface ContactInfo {
  contacts: Contact[] | null;
  total: number;
  last_page: number;
}

type TradeSortLists = "latest" | "oldest";
interface ContactInfoInitialState {
  isLoading: boolean;
  data: ContactInfo;
}
interface TradeInitialState {
  isLoading: boolean;
  sort: TradeSortLists;
  showOnly: TradeStatus | "all";
  data: Trade[];
  visibleTrades: Trade[];
}

const initialState: ContactInfoInitialState = {
  isLoading: false,
  data: {
    contacts: null,
    last_page: 0,
    total: 0,
  },
};

const initialTradeState: TradeInitialState = {
  isLoading: false,
  sort: "latest",
  showOnly: "all",
  data: [],
  visibleTrades: [],
};

export const ContactInfoSlice = createSlice({
  name: "contactsInfo",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactsInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContactsInfo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getContactsInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload || initialState.data;
      });
  },
});

export const contactsInfo = ContactInfoSlice.reducer;

export const sortTrade = createAction<TradeSortLists>("trades/sort");
export const hideTradeExcept = createAction<TradeStatus | "all">(
  "trades/showonly"
);

export const TradeSlice = createSlice({
  name: "trades",
  initialState: initialTradeState,
  reducers: {
    addTrade: {
      reducer: (state, action: PayloadAction<Trade>) => {
        const trade = action.payload;
        const rest = state.data.filter((t) => t.id !== trade.id);
        const restV = state.visibleTrades.filter((t) => t.id !== trade.id);
        state.data = [trade, ...rest];
        state.visibleTrades = [trade, ...restV];
      },
      prepare: (trade: Trade) => {
        return { payload: trade };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrades.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTrades.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTrades.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = sortByDate(
          action.payload || initialState.data,
          state.sort
        );
        if (state.showOnly === "all") {
          state.visibleTrades = state.data;
        } else {
          state.visibleTrades = state.data.filter(
            (d) => d.status === state.showOnly
          );
        }
      })
      .addCase(sortTrade, (state, action) => {
        const by = action.payload;
        state.visibleTrades = sortByDate(state.visibleTrades, by);
        state.sort = by;
      })
      .addCase(hideTradeExcept, (state, action) => {
        const only = action.payload;
        if (only === "all") {
          state.visibleTrades = state.data;
          state.showOnly = only;
        } else {
          state.visibleTrades = state.data.filter((d) => d.status === only);
          state.showOnly = only;
        }
      });
  },
});

export interface tokenStatus {
  status: "approve" | "reject";
  token: string;
}

const currentTrade = createSlice({
  name: "currentTrade",
  initialState: null as tokenStatus | null,
  reducers: {
    clearCurrentTrade(_state) {
      return null;
    },
    addCurrentTrade(_state, action: PayloadAction<tokenStatus>) {
      return action.payload || null;
    },
  },
});

export const { clearCurrentTrade, addCurrentTrade } = currentTrade.actions;
export const currentTradeReducer = currentTrade.reducer;
export const trades = TradeSlice.reducer;
