import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CryptoCurrency,
  CurrencyValue,
  getAllCurrencies,
  getAllGiftcards,
  getAllNetworks,
  GiftCardValue,
  NetworkReturnValue,
  showAllCrpyto,
} from "./thunkActions";

// Network
interface NetworkInitialState {
  loading: boolean;
  networkData: NetworkReturnValue[];
}
const initialNetwork: NetworkReturnValue[] = [];
const initialNetworkState: NetworkInitialState = {
  loading: false,
  networkData: initialNetwork,
};
export const networkSlice = createSlice({
  name: "networks",
  initialState: initialNetworkState,
  reducers: {
    removeAppNetwork: {
      reducer: (state, action: PayloadAction<NetworkReturnValue>) => {
        const filterNetworks = state.networkData.filter(
          (item) => item.id !== action.payload.id
        );
        state.networkData = filterNetworks;
      },
      prepare: (network: NetworkReturnValue) => {
        return { payload: network };
      },
    },
    updateAppNetwork: {
      reducer: (state, action: PayloadAction<NetworkReturnValue>) => {
        const updatedData = state.networkData.map(
          (info: NetworkReturnValue) => {
            if (info.id === action.payload.id) {
              return action.payload;
            } else {
              return info;
            }
          }
        );
        state.networkData = updatedData;
      },
      prepare: (network: NetworkReturnValue) => {
        return { payload: network };
      },
    },
    addAppNetwork: {
      reducer: (state, action: PayloadAction<NetworkReturnValue>) => {
        const rest = state.networkData.filter(
          (t) => t.id !== action.payload.id
        );
        state.networkData = [...rest, action.payload];
      },
      prepare: (network: NetworkReturnValue) => {
        return { payload: network };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNetworks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNetworks.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAllNetworks.fulfilled, (state, action) => {
        state.loading = false;
        state.networkData = action.payload || initialNetworkState.networkData;
      });
  },
});

// Cryptocurrency
interface CryptosInitialState {
  isLoading: boolean;
  data: CryptoCurrency[];
}
const initial: CryptoCurrency[] = [];
const initialState: CryptosInitialState = {
  isLoading: false,
  data: initial,
};
export const cryptoSlice = createSlice({
  name: "cryptos",
  initialState,
  reducers: {
    removeAppCrypto: {
      reducer: (state, action: PayloadAction<CryptoCurrency>) => {
        const filterCurrencies = state.data.filter(
          (item) => item.id !== action.payload.id
        );
        state.data = filterCurrencies;
      },
      prepare: (crypto: CryptoCurrency) => {
        return { payload: crypto };
      },
    },
    updateAppCrypto: {
      reducer: (state, action: PayloadAction<CryptoCurrency>) => {
        const updatedData = state.data.map((info: CryptoCurrency) => {
          if (info.id === action.payload.id) {
            return action.payload;
          } else {
            return info;
          }
        });
        state.data = updatedData;
      },
      prepare: (crypto: CryptoCurrency) => {
        return { payload: crypto };
      },
    },
    addAppCrypto: {
      reducer: (state, action: PayloadAction<CryptoCurrency>) => {
        const rest = state.data.filter((t) => t.id !== action.payload.id);
        state.data = [...rest, action.payload];
      },
      prepare: (crypto: CryptoCurrency) => {
        return { payload: crypto };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(showAllCrpyto.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(showAllCrpyto.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(showAllCrpyto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload || initialState;
      });
  },
});

// Currency
const initialCurrency: CurrencyValue[] = [];
const initialCurrencyState: { loading: boolean; data: CurrencyValue[] } = {
  loading: false,
  data: initialCurrency,
};
export const currencySlice = createSlice({
  name: "currencies",
  initialState: initialCurrencyState,
  reducers: {
    removeAppCurrency: {
      reducer: (state, action: PayloadAction<CurrencyValue>) => {
        const filterCurrencies = state.data.filter(
          (item) => item.id !== action.payload.id
        );
        state.data = filterCurrencies;
      },
      prepare: (currency: CurrencyValue) => {
        return { payload: currency };
      },
    },
    updateAppCurrency: {
      reducer: (state, action: PayloadAction<CurrencyValue>) => {
        const updatedData = state.data.map((info: CurrencyValue) => {
          if (info.id === action.payload.id) {
            return action.payload;
          } else {
            return info;
          }
        });
        state.data = updatedData;
      },
      prepare: (currency: CurrencyValue) => {
        return { payload: currency };
      },
    },
    addAppCurrency: {
      reducer: (state, action: PayloadAction<CurrencyValue>) => {
        const rest = state.data.filter((t) => t.id !== action.payload.id);
        state.data = [...rest, action.payload];
      },
      prepare: (currency: CurrencyValue) => {
        return { payload: currency };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCurrencies.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload || [];
      });
  },
});

// Giftcard
const initialGiftcard: GiftCardValue[] = [];
const initialGiftcardState: { loading: boolean; data: GiftCardValue[] } = {
  loading: false,
  data: initialGiftcard,
};
export const giftcardSlice = createSlice({
  name: "giftcard",
  initialState: initialGiftcardState,
  reducers: {
    removeAppGiftcard: {
      reducer: (state, action: PayloadAction<GiftCardValue>) => {
        const filterCards = state.data.filter(
          (item) => item.id !== action.payload.id
        );
        state.data = filterCards;
      },
      prepare: (cards: GiftCardValue) => {
        return { payload: cards };
      },
    },
    updateAppGiftcard: {
      reducer: (state, action: PayloadAction<GiftCardValue>) => {
        const updatedData = state.data.map((info: GiftCardValue) => {
          if (info.id === action.payload.id) {
            return action.payload;
          } else {
            return info;
          }
        });
        state.data = updatedData;
      },
      prepare: (currency: GiftCardValue) => {
        return { payload: currency };
      },
    },
    addAppGiftcard: {
      reducer: (state, action: PayloadAction<GiftCardValue>) => {
        const rest = state.data.filter((t) => t.id !== action.payload.id);
        state.data = [...rest, action.payload];
      },
      prepare: (currency: GiftCardValue) => {
        return { payload: currency };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGiftcards.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGiftcards.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllGiftcards.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload || [];
      });
  },
});

// Exports
export const networksReducer = networkSlice.reducer;
export const currencyReducer = currencySlice.reducer;
export const giftcardReducer = giftcardSlice.reducer;
export default cryptoSlice.reducer;
export * from "./thunkActions";
export const { removeAppCurrency, updateAppCurrency, addAppCurrency } =
  currencySlice.actions;

export const { addAppGiftcard, removeAppGiftcard, updateAppGiftcard } =
  giftcardSlice.actions;
export const { addAppNetwork, updateAppNetwork, removeAppNetwork } =
  networkSlice.actions;
export const { addAppCrypto, updateAppCrypto, removeAppCrypto } =
  cryptoSlice.actions;
