import { useState, useRef, useEffect,useCallback } from 'react';

import data from '../data/data.json';
import { ChevronLeft, ChevronRight } from "react-feather";


const Carousel = () => {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }else{
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const moveNext =  useCallback(() => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
    else {
      setCurrentIndex((prevState) => prevState - 1);
    }
  });

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="carousel my-12 mx-auto">
   
      <div className="relative overflow-hidden">
        <div className="flex justify-between absolute top left inset-0  md:-top-5  items-center">
          <button
            onClick={movePrev}
            className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776] disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            // disabled={isDisabled('prev')}
          >
           <ChevronLeft size={30} color="#fff"  />
          </button>
          <button
            onClick={moveNext}
            className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776] disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            // disabled={isDisabled('next')}
          >
         <ChevronRight size={30} color="#fff"  />
          </button>
        </div>
        <div
          ref={carousel}
          className="carousel-container relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
        >
          {data.resources.map((resource, index) => {
            return (
              <div
                key={index}
                className="carousel-item text-center relative w-80 h-80 snap-start"
              >
          
                <a
                  href={resource.link}
                  className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
                  style={{ backgroundImage: `url(${resource.imageUrl || ''})` }}
                >
                  
                  <img
                    src={resource.imageUrl || ''}
                    alt={resource.title}
                    //className="relative w-[100%] md:w-[100%] "
                    className="w-full aspect-square hidden"
                  />
                  
                </a>
                <a
                  href={resource.link}
                  style={{height:'100px'}}
                  className="w-full aspect-square block absolute bottom-0 left-0 transition-opacity duration-300 opacity-0 hover:opacity-100 bg-blue-900/45 z-10"
                 // className="h-full w-full aspect-square block absolute top-0 left-0 transition-opacity duration-300  bg-blue-800/75 z-10"
                >
                  {/* opacity-0 hover:opacity-100 */}
                  <h3 className="text-white py-1 px-3 mx-auto text-xl">
                  Property Location
                </h3>
                <h3 className="text-white py-1 px-3 mx-auto text-xl">
                  {resource.title}
                </h3>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;



// const Carousels = () => {
//   const maxScrollWidth = useRef(0);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const carousels = useRef(null);

//   const movePrev = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex((prevState) => prevState - 1);
//     }
//   };

//   const moveNext = () => {
//     if (
//       carousels.current !== null &&
//       carousels.current.offsetWidth * currentIndex <= maxScrollWidth.current
//     ) {
//       setCurrentIndex((prevState) => prevState + 1);
//     }
//   };

//   const isDisabled = (direction) => {
//     if (direction === 'prev') {
//       return currentIndex <= 0;
//     }

//     if (direction === 'next' && carousels.current !== null) {
//       return (
//         carousels.current.offsetWidth * currentIndex >= maxScrollWidth.current
//       );
//     }

//     return false;
//   };

//   useEffect(() => {
//     if (carousels !== null && carousels.current !== null) {
//       carousels.current.scrollLeft = carousels.current.offsetWidth * currentIndex;
//     }
//   }, [currentIndex]);

//   useEffect(() => {
//     maxScrollWidth.current = carousels.current
//       ? carousels.current.scrollWidth - carousels.current.offsetWidth
//       : 0;
//   }, []);

//   return (
// <>

//     <div className="carousel my-12 mx-auto">

//     <div className="relative overflow-hidden">
//       <div 
//       className="flex justify-between absolute top left inset-0  md:-top-5  items-center  "
//       // className="flex justify-between absolute top left w-full h-full"
//       >
//         <button
//           onClick={movePrev}
//           className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776] disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
//           // className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776]"
//           disabled={isDisabled('prev')}
//         >
//          <ChevronLeft size={20} color="#fff"  />
//         </button>
//         <button
//           onClick={moveNext}
//           className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776] disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
//           // className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776] "
//           disabled={isDisabled('next')}
//         >
//            <ChevronRight size={20} color="#fff"  />
    
//         </button>
//       </div>
//       <div
//         ref={carousels}
//         className="carousel-container relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
//       >
//         {data.resources.map((resource, index) => {
//           return (
//             <div
//               key={index}
//               className="carousel-item text-center relative w-[100%] h-200 snap-start mx-4"
//             >
//               <a
//                 href={resource.link}
//                 // className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
//                 // style={{ backgroundImage: `url(${resource.imageUrl || ''})` }}
//               >
//                 <img
//                   src={resource.imageUrl || ''}
//                   alt={resource.title}
//                   className="relative w-[100%] md:w-[100%] h-200"
//                 />
//               </a>
//               <a
//                 href={resource.link}
//                 className="h-full w-full aspect-square block absolute top-0 left-0 transition-opacity duration-300 opacity-0 hover:opacity-100 bg-blue-800/75 z-10"
//               >
                //  <h3 className="text-white py-6 px-3 mx-auto text-xl">
                //   Property Location
                // </h3>
                // <h3 className="text-white py-3 px-3 mx-auto text-xl">
                //   {resource.title}
                // </h3>
//               </a>
//             </div>
//           );
//         })}
//       </div>
//     </div>
    
//   </div>
//   </>

//   );
// };

// export default Carousels;




//import React from 'react';


//   import { ChevronLeft, ChevronRight } from "react-feather";
// import Onboarding from "../components/Onboarding";
// import img1 from "../utils/h1.jpeg";
// import img2 from "../utils/h2.jpeg";
// import img3 from "../utils/4145.jpg";
// import img5 from "../utils/2217(3).jpeg";

//   export default function Slider() {
//     const [openPopover, setOpenPopover] = React.useState(false);
//     const slides = [img1, img2, img3];
//     const triggers = {
//       onMouseEnter: () => setOpenPopover(true),
//       onMouseLeave: () => setOpenPopover(false),
//     };
//     const theme = {
//       carousel: {
//         defaultProps: {
//           prevArrow: ({ loop, handlePrev, firstIndex }) => {
//             return (
//               <button
//                 onClick={handlePrev}
//                 disabled={!loop && firstIndex}
//                 className="!absolute top-2/4 left-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-white/10 active:bg-white/30 grid place-items-center"
//               >
//                 {/* <ChevronLeftIcon strokeWidth={3} className="-ml-1 h-7 w-7" /> */}
//                 <ChevronLeft size={20} color="#fff" />
//               </button>
//             );
//           },
//           nextArrow: ({ loop, handleNext, lastIndex }) => (
//             <button
//               onClick={handleNext}
//               disabled={!loop && lastIndex}
//               // className="!absolute top-2/4 right-4 -translate-y-2/4 rounded-full select-none transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[48px] h-12 max-h-[48px] text-white hover:bg-[#00B776] active:bg-[#00B776] grid place-items-center"
//               className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776]"
//              >
//               {/* <ChevronRightIcon strokeWidth={3} className="ml-1 h-7 w-7" /> */}
//               <ChevronRight size={20} color="#fff"  />
//             </button>
//           ),
//           navigation: ({ setActiveIndex, activeIndex, length }) => (
//             <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
//               {new Array(length).fill("").map((_, i) => (
//                 <span
//                   key={i}
//                   className={`block h-3 w-3 cursor-pointer rounded-full transition-colors content-[''] ${
//                     activeIndex === i ? "bg-white" : "bg-white/50"
//                   }`}
//                   onClick={() => setActiveIndex(i)}
//                 />
//               ))}
//             </div>
//           ),
//           autoplay: false,
//           autoplayDelay: 5000,
//           transition: {
//             type: "tween",
//             duration: 0.5,
//           },
//           loop: false,
//           className: "",
//         },
//         styles: {
//           base: {
//             carousel: {
//               position: "relative",
//               width: "w-full",
//               height: "h-full",
//               overflowX: "overflow-x-hidden",
//               display: "flex",
//             },
     
//             slide: {
//               width: "w-full",
//               height: "h-full",
//               display: "inline-block",
//               flex: "flex-none",
//             },
//           },
//         },
//       },
//     };
//     return (
//         <>
// <div className="overflow-hidden md:overflow-hidden relative">
//       <div
//         className="flex md:float-none transition-transform ease-out items-center duration-500 "
      
//       >

// <Carousel className="rounded-xl"
// style={theme}

// // navigation={({ setActiveIndex, activeIndex, length }) => (
// //   <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
// //     {new Array(length).fill("").map((_, i) => (
// //       <span
// //         key={i}
// //         className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
// //           activeIndex === i ? "w-8 bg-[#00B776]" : "w-4 bg-white/50"
// //         }`}
// //         onClick={() => setActiveIndex(i)}
// //       />
// //     ))}
// //   </div>
// // )}

// // prevArrow={({ handlePrev }) => (
// //   <button
// //          onClick={handlePrev}
// //           className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776]"
// //         >
// //           <ChevronLeft size={20} color="#fff" />
// //         </button>
// // )}
// // nextArrow={({ handleNext }) => (
// //   <button
// //           onClick={handleNext}
// //           className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776]"
// //         >
// //           <ChevronRight size={20} color="#fff"  />
// //         </button>
// // )}

// >
 
//       <img
//         src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
//         alt="image 1"
//         className="h-full w-full object-cover"
//       />
//       <img
//         src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
//         alt="image 2"
//         className="h-full w-full object-cover"
//       />
//       <img
//         src="https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80"
//         alt="image 3"
//         className="h-full w-full object-cover"
//       />

// <div className="w-[100%] bg-red-100 mx-4 relative group">
//   <img  src={img1} className='relative w-[100%] mx-4 hover:bg-[#00B776]' 
//    style={{ height: '340px' }}/>

//   <div className="opacity-0 group-hover:opacity-100 duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-gray-200 text-black font-semibold">Dwayne gfgrtyt trtrr trtrtr</div>

// </div> 

// <img
                 
//                  className="relative w-[100%]  mx-4"
//                  src={img2}
//                  alt="amazing-property"
//                  style={{ height: '340px' }}

//                />
//     </Carousel>

//     {/* <div className="absolute inset-0  md:-top-5 flex items-center justify-between p-4 ">
//         <button
//           // onClick={prev}
//           className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776]"
//         >
//           <ChevronLeft size={20} color="#fff" />
//         </button>
//         <button
//           // onClick={next}
//           className="p-1 rounded-full shadow bg-primary/80 text-gray-800 hover:bg-[#00B776]"
//         >
//           <ChevronRight size={20} color="#fff"  />
//         </button>
//       </div> */}

//       </div>
//       </div>

//  {/* <Carousel
//       className="rounded-xl"
//       prevArrow={({ handlePrev }) => (
//         <IconButton
//           variant="text"
//           color="white"
//           size="lg"
//           onClick={handlePrev}
//           className="!absolute top-2/4 left-4 -translate-y-2/4"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth={2}
//             stroke="currentColor"
//             className="h-6 w-6"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
//             />
//           </svg>
//         </IconButton>
//       )}
//       nextArrow={({ handleNext }) => (
//         <IconButton
//           variant="text"
//           color="white"
//           size="lg"
//           onClick={handleNext}
//           className="!absolute top-2/4 !right-4 -translate-y-2/4"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth={2}
//             stroke="currentColor"
//             className="h-6 w-6"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
//             />
//           </svg>
//         </IconButton>
//       )}
//     >
//       <img
//         src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
//         alt="image 1"
//         className="h-full w-full object-cover"
//       />
//       <img
//         src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
//         alt="image 2"
//         className="h-full w-full object-cover"
//       />
//       <img
//         src="https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80"
//         alt="image 3"
//         className="h-full w-full object-cover"
//       />
//     </Carousel> */}

//           <Onboarding >
//               {slides.map((s) => (
//                 <>
    
             

// <Popover open={openPopover} handler={setOpenPopover}>
//         <PopoverHandler {...triggers}>
         
//         <img
//                src={s}
//                className="relative w-[100%] hover:bg-[#00B776]  mx-4"
//                 alt=""
//                 style={{ height: '340px' }}
//               />
          
//         </PopoverHandler>
//         <PopoverContent {...triggers} className="z-50 max-w-[26rem]">
//           <div className="mb-2 flex items-center gap-3">
//             <Typography
//               as="a"
//               href="#"
//               variant="h6"
//               color="blue-gray"
//               className="font-bold transition-colors hover:text-gray-900"
//             >
//               @material-tailwind
//             </Typography>
//             <Chip
//               value="Public"
//               className="rounded-full px-2 py-1 font-medium capitalize tracking-wide"
//             />
//           </div>
//           <Typography
//             variant="small"
//             color="gray"
//             className="font-normal text-blue-gray-500"
//           >
//             @material-tailwind is an easy-to-use components library for Tailwind
//             CSS and Material Design.
//           </Typography>
//           <div className="mt-4 flex items-center gap-5">
//             <div className="flex items-center gap-1">
//               <span className="h-3 w-3 rounded-full bg-blue-700" />
//               <Typography
//                 color="gray"
//                 className="text-xs font-medium text-blue-gray-500"
//               >
//                 TypeScript
//               </Typography>
//             </div>
//             <div className="flex items-center gap-1">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//                 className="-mt-0.5 h-4 w-4 text-yellow-700"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//               <Typography
//                 color="gray"
//                 className="text-xs font-medium text-blue-gray-500"
//               >
//                 1,480
//               </Typography>
//             </div>
//             <div className="flex items-center gap-1">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//                 className="-mt-px h-4 w-4 text-green-500"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//               <Typography
//                 color="gray"
//                 className="text-xs font-medium text-blue-gray-500"
//               >
//                 Veritied
//               </Typography>
//             </div>
//           </div>
//         </PopoverContent>
//       </Popover>
                

//               <img
                 
//                     className="relative w-[100%]  mx-4"
//                     src={s}
//                     alt="amazing-property"
//                     style={{ height: '340px' }}

//                   />

//                   <img
//                     className="relative w-[100%] mx-4"
//                     src={s}
//                     alt="amazing-property"
//                     style={{ height: '340px' }}
//                   />



// <div className="w-[100%] bg-red-100 mx-4 relative group">
//   <img  src={s} className='relative w-[100%] mx-4 hover:bg-[#00B776]' 
//    style={{ height: '340px' }}/>

//   <div className="opacity-0 group-hover:opacity-100 duration-300 absolute inset-x-0 bottom-0 flex justify-center items-end text-xl bg-gray-200 text-black font-semibold">Dwayne gfgrtyt trtrr trtrtr</div>

// </div>  



//                 </>


//               ))}
//             </Onboarding> 
// {/*  

// <Carousel
//       className="rounded-xl"
//       prevArrow={({ handlePrev }) => (
//         <IconButton
//           variant="text"
//           color="white"
//           size="lg"
//           onClick={handlePrev}
//           className="!absolute top-2/4 left-4 -translate-y-2/4"
//         >
//      <ChevronLeft size={20} color="#fff" />
//         </IconButton>


//       )}
//       nextArrow={({ handleNext }) => (

//         <IconButton
//           variant="text"
//           color="white"
//           size="lg"
//           onClick={handleNext}
//           className="!absolute top-2/4 !right-4 -translate-y-2/4"
//         >
//       <ChevronRight size={20} color="#fff" />
//         </IconButton>
//       )}
//     >
//         <Popover open={openPopover} handler={setOpenPopover}>
//         <PopoverHandler {...triggers}>
//         <img
//                src={img1}
//                className="h-full w-full object-cover"
//                 alt=""
//               />
//         </PopoverHandler>
//         <PopoverContent {...triggers} className="z-50 max-w-[26rem]">
//           <div className="mb-2 flex items-center gap-3">
//             <Typography
//               as="a"
//               href="#"
//               variant="h6"
//               color="blue-gray"
//               className="font-bold transition-colors hover:text-gray-900"
//             >
//               @material-tailwind
//             </Typography>
//             <Chip
//               value="Public"
//               className="rounded-full px-2 py-1 font-medium capitalize tracking-wide"
//             />
//           </div>
//           <Typography
//             variant="small"
//             color="gray"
//             className="font-normal text-blue-gray-500"
//           >
//             @material-tailwind is an easy-to-use components library for Tailwind
//             CSS and Material Design.
//           </Typography>
//           <div className="mt-4 flex items-center gap-5">
//             <div className="flex items-center gap-1">
//               <span className="h-3 w-3 rounded-full bg-blue-700" />
//               <Typography
//                 color="gray"
//                 className="text-xs font-medium text-blue-gray-500"
//               >
//                 TypeScript
//               </Typography>
//             </div>
//             <div className="flex items-center gap-1">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//                 className="-mt-0.5 h-4 w-4 text-yellow-700"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//               <Typography
//                 color="gray"
//                 className="text-xs font-medium text-blue-gray-500"
//               >
//                 1,480
//               </Typography>
//             </div>
//             <div className="flex items-center gap-1">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//                 className="-mt-px h-4 w-4 text-green-500"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//               <Typography
//                 color="gray"
//                 className="text-xs font-medium text-blue-gray-500"
//               >
//                 Veritied
//               </Typography>
//             </div>
//           </div>
//         </PopoverContent>
//       </Popover>
               
//                    <img
//                src={img2}
//                className="h-full w-full object-cover"
//                 alt=""
//               />
//                 <img
//                src={img3}
//                className="h-full w-full object-cover"
//                 alt=""
//               />
//     </Carousel>
    
//     */}
      
//       </>
//     );
//   }
  
// // import 'mdb-ui-kit/css/mdb.min.css';
// // import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
// // // import { MDBCarousel, MDBCarouselItem } from 'mdb-ui-kit';
// // import { MDBMask, MDBView,  MDBRow, MDBCol,MDBContainer } from "mdbreact";
// // import 'mdb-ui-kit/css/mdb.min.css';
// // import img1 from "../utils/h1.jpeg";
// // import img2 from "../utils/h2.jpeg";
// // import img3 from "../utils/4145.jpg";
// // export default function Slider() {
// //   return (
// //     <MDBContainer>
// //     <MDBCarousel showControls showIndicators touch={false}>
// //     <MDBCarouselItem itemId={1}>
// //     <MDBRow>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                src={img1}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-strong">
// //                 <p className="white-text">Strong overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                src={img2}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-light">
// //                 <p className="white-text">Light overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                 src={img3}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-slight">
// //                 <p className="white-text">Super light overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //         </MDBRow>
// //     </MDBCarouselItem>
// //     <MDBCarouselItem itemId={2}>
// //     <MDBRow>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                src={img1}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-strong">
// //                 <p className="white-text">Strong overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //           <MDBCol md="4">
// //             <MDBView hover>
//             //   <img
//             //    src={img2}
//             //     className="img-fluid"
//             //     alt=""
//             //   />
// //               <MDBMask className="flex-center" overlay="red-light">
// //                 <p className="white-text">Light overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                 src={img3}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-slight">
// //                 <p className="white-text">Super light overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //         </MDBRow>
// //     </MDBCarouselItem>
// //     <MDBCarouselItem itemId={3}>
// //     <MDBRow>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                src={img1}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-strong">
// //                 <p className="white-text">Strong overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                src={img2}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-light">
// //                 <p className="white-text">Light overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //           <MDBCol md="4">
// //             <MDBView hover>
// //               <img
// //                 src={img3}
// //                 className="img-fluid"
// //                 alt=""
// //               />
// //               <MDBMask className="flex-center" overlay="red-slight">
// //                 <p className="white-text">Super light overlay</p>
// //               </MDBMask>
// //             </MDBView>
// //           </MDBCol>
// //         </MDBRow>
// //     </MDBCarouselItem>
// //   </MDBCarousel>
// //   </MDBContainer>
// //   );
// // }