import React, { ReactNode, useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import { FadeIn } from "../data/variants";
import cls from "classnames";

interface ModalProps {
  title: string;
  children: ReactNode;
  className?: string;
  onClose: () => void;
  large?: boolean;
  innerDivClassName?: string;
}

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  innerDivClassName,
  className,
  onClose,
  large,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    modalRef.current?.focus();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <motion.div
        role="dialog"
        variants={FadeIn}
        animate="visible"
        exit="hidden"
        initial="hidden"
        transition={{ duration: 0.5 }}
        tabIndex={-1}
        className={cls(
          "h-screen w-screen inset-0 bg-opacity-50 z-40 bg-black fixed flex items-center justify-center overflow-auto p-[1rem]",
          className
        )}
      >
        <div
          role="document"
          tabIndex={0}
          ref={modalRef}
          className={cls(
            "w-screen m-4 bg-white p-6 rounded-[5px]",
            { "w-[47rem]": large },
            { "max-w-lg": !large },
            innerDivClassName
          )}
        >
          <div className="flex justify-between items-center">
            <h3 className="font-[500] text-[1em] sm:text-[1.625em] text-back underline">
              {title}
            </h3>
            <div className="flex justify-end text-2xl mb-3">
              <MdClose
                className="cursor-pointer text-black"
                onClick={onClose}
              />
            </div>
          </div>
          {children}
        </div>
      </motion.div>
    </>
  );
};

export default Modal;
