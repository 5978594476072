import React, { useState } from "react";
import { errorToast, successToast } from "../../app/toasts";
import http from "../../utils/http";
import * as Yup from "yup";
import Modal from "../PopModal";
import { Form, Formik } from "formik";
import InputField from "../inputs/InputField";
import LoadingPage from "../LoadingPage";
import { NetworkReturnValue } from "../../features/asset/thunkActions";
import { useAppDispatch } from "../../app/hooks";
import { addAppNetwork, updateAppNetwork } from "../../features/asset/slice";

type Props = {
  network?: NetworkReturnValue;
  onClose: () => void;
};

const AddNetworkSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  short_name: Yup.string().min(2).required(),
});

const AddNetwork = ({ network, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const addNetwork = (values: { name: string; short_name: string }) => {
    setLoading(true);
    const onSuccess = (data: any) => {
      setLoading(false);
      if (network) {
        successToast("Network Updated");
        dispatch(
          updateAppNetwork({
            id: network.id,
            ...values,
          })
        );
      } else {
        successToast(`${values.name} has been added successfully`);
        dispatch(
          addAppNetwork({
            id: data.id,
            name: data.name,
            short_name: data.short_name,
          })
        );
      }
      onClose();
    };

    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured, please try again");
    };
    if (network) {
      http
        .post(`/network/edit/${network.id}`, values)
        .then(onSuccess)
        .catch(onFailure);
    } else {
      http.post("/network/create", values).then(onSuccess).catch(onFailure);
    }
  };

  return (
    <Modal
      title={network ? "Edit Network" : `Add New Network`}
      innerDivClassName="mt-6"
      onClose={onClose}
    >
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <Formik
          validationSchema={AddNetworkSchema}
          initialValues={{
            name: network?.name || "",
            short_name: network?.short_name || "",
          }}
          onSubmit={(e) => {
            addNetwork(e);
          }}
        >
          {() => (
            <Form className="w-full space-y-4">
              <InputField name="name" placeholder="Name" label="Network Name" />
              <InputField
                name="short_name"
                placeholder="Bep20"
                label="Network Short Name"
              />

              {loading ? (
                <LoadingPage />
              ) : (
                <div className="flex space-x-4 items-center justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-primary bg-transparent py-2 px-4 rounded "
                  >
                    Cancel
                  </button>
                  <button
                    type={"submit"}
                    className="bg-primary text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddNetwork;
