import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Layouts/Section";
import { motion } from "framer-motion";
import AnimVariants from "../data/variants";
import { AiOutlineMail } from "react-icons/ai";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  return (
    <Layout layout="headerAndFooter">
      <motion.div
        variants={AnimVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <Section
          containerClass="bg-cover bg-center bg-[url('./assets/img/about-us-bg.png')]"
          className="flex flex-col gap-20 items-center justify-center"
          padding
        >
          <div className="relative w-full text-center">
            <h2 className="text-[#19233b] font-medium leading-[46px] text-[30px] lg:text-[36px]">
              Contact Us
            </h2>
            <p className="w-full py-8 -mt-2 mx-auto text-[#7a839e] text-base intro sm:max-w-2xl"></p>
          </div>
        </Section>
        <Section padding containerClass="" className="bg-opacity-10">
          <h2 className="text-[#19233b] text-center font-medium leading-[46px] text-[30px] lg:text-[36px] my-10">
            You can always reach us through multiple channels
          </h2>
          <div className="grid grid-cols-1 md:lg:xl:grid-cols-3 group bg-white shadow-xl shadow-neutral-100 border ">
            <a
              href="mailto:contact@amazingpropertiesusa.com"
              className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50
                cursor-pointer"
            >
              <span className="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200">
                <AiOutlineMail color="white" size={32} />
              </span>
              <p className="text-xl font-medium text-slate-700 mt-3">
                spectrum-finance.online/
              </p>
              <p className="mt-2 text-sm text-slate-500"></p>
            </a>

            <a
              href="https://api.whatsapp.com/send?phone=2347083925376"
              className="p-10 flex flex-col items-center text-center group   md:lg:xl:border-r hover:bg-slate-50 cursor-pointer"
            >
              <span className="p-5 rounded-full bg-lime-500 text-white shadow-lg shadow-lime-200">
                <BsWhatsapp color="white" size={32} />
              </span>
              <p className="text-xl font-medium text-slate-700 mt-3">
                +2347083925376
              </p>
              <p className="mt-2 text-sm text-slate-500"></p>
            </a>

            <a
              href="https://instagram.com"
              className="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer"
            >
              <span className="p-5 rounded-full bg-indigo-500 text-white shadow-lg shadow-indigo-200">
                <BsInstagram color="white" size={32} />
              </span>
              <p className="text-xl font-medium text-slate-700 mt-3">
                @spectrumofficial
              </p>
              <p className="mt-2 text-sm text-slate-500"></p>
            </a>
          </div>
        </Section>
      </motion.div>
    </Layout>
  );
};

export default Contact;
