import { Menu } from "@headlessui/react";
import React from "react";

type MarkTradeButtonProps = {
  onMarkComplete?: () => void;
  onMarkCancel?: () => void;
};

const MarkTradeButton = ({
  onMarkCancel,
  onMarkComplete,
}: MarkTradeButtonProps) => {
  return (
    <Menu>
      <Menu.Button className="bg-primary text-white py-3 px-6">
        Mark As
      </Menu.Button>
      <Menu.Items>
        <Menu.Item>
          <button
            onClick={() => {
              if (onMarkComplete) {
                onMarkComplete();
              }
            }}
            type="button"
            className="text-emerald-600 px-3"
          >
            Completed
          </button>
        </Menu.Item>
        <Menu.Item>
          <button
            onClick={() => {
              if (onMarkCancel) {
                onMarkCancel();
              }
            }}
            type="button"
            className="text-red-600 px-3"
          >
            Cancel
          </button>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default MarkTradeButton;
