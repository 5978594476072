import React from "react";
import AcrossCountries from "../components/Home/AcrossCountries";
import DiscoverMore from "../components/Home/DiscoverMore";
import Hero from "../components/Home/Hero";
import Trade from "../components/Home/Trade";
import TrustUs from "../components/Home/TrustUs";
import TradeWithoutAccount from "../components/Home/TradeWithoutAccount";
import { motion } from "framer-motion";
import AnimVariants from "../data/variants";
import Layout from "../components/Layout";

const Home = () => {
  return (
    <Layout>
      <motion.div
        variants={AnimVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <Hero />
        <Trade />
        <TrustUs />
        <TradeWithoutAccount />
        <AcrossCountries />
        <DiscoverMore />
      </motion.div>
    </Layout>
  );
};

export default Home;
