import React from "react";
import { Link } from "react-router-dom";
import Container from "../utility/Container";
import AccountNavItems from "./AccountNavItems";
import Logo from "../../utils/logo.svg";

type NavigationProps = {
  onClick: () => void;
};

const AccountNavBar = ({ onClick }: NavigationProps) => {
  return (
    <header className="bg-gray-100 w-full flex static items-center justify-center pt-2 pb-2 md:pt-2 md:pb-1">
      <Container className="w-10/12 lg:!w-[95%]">
        <div className="w-full flex items-center justify-between">
          <Link to="/" className="flex gap-x-5 items-center justify-center">
            <img
              src={Logo}
              className="w-[105px] md:w-[220px]"
              alt="Spectrum logo"
            />
          </Link>
          <nav>
            <AccountNavItems
              containerClass="hidden lg:flex items-center justify-center gap-x-7"
              onClick={onClick}
            />
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default AccountNavBar;
