import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { MdSend } from "react-icons/md";
import { BsImages } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import MsgBox from "../components/utility/MsgBox";
import {
  Chat,
  ChatSendValue,
  giftcard_seller_detail,
  locationStateProps,
  sell_crypto_detail,
} from "../ts/interfaces/main";
import { getUser } from "../features/auth/thunkActions";
import { v4 as uuidv4, v4 } from "uuid";
import http from "../utils/http";
import { errorToast } from "../app/toasts";
import { Field, Formik, FormikState } from "formik";
import useAppSocket from "../app/useAppSocket";
import { getFileFromUrl } from "../utils";
import { ActionTypes, reducer } from "./AdminChatSection";

// const image_url_prefix = "https://foreignflip.com/storage/attachments/";

const routes = {
  sendMessage: "/messages/send",
};

export interface Retry {
  retry: boolean;
  uuid: string;
}

const ChatSection = () => {
  const [messages, dispatch] = useReducer(reducer, []);
  const divRef = React.useRef<HTMLDivElement>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [trade_ended, setTrade_ended] = useState(false);
  const [adminId, setAdminId] = useState<number | null>(null);
  const user = useMemo(() => getUser(), []);

  const scrollToBottom = useCallback(() => {
    const currentDiv = divRef.current;
    if (currentDiv) {
      setTimeout(() => {
        currentDiv.scrollTo(0, currentDiv.scrollHeight);
      }, 500);
    }
  }, [divRef]);

  const HandleSend = useCallback(
    (
      { msg }: ChatSendValue,
      resetForm?: (
        nextState?: Partial<FormikState<{ msg: string }>> | undefined
      ) => void,
      retry?: Retry
    ): void => {
      if (user) {
        const messageData = {
          message: msg,
          // room_id: uniqueRoomID,
          room_id: "testing",
          email: user.email,
          type: "text",
          to_id: adminId?.toString(),
        };

        if (retry) {
          if (retry.retry) {
            if (retry.uuid) {
              dispatch({ type: ActionTypes.RETRY, payload: retry.uuid });
              http
                .post(routes.sendMessage, messageData)
                .then(() => {
                  dispatch({ type: ActionTypes.SENT, payload: retry.uuid });
                })
                .catch((e) => {
                  dispatch({ type: ActionTypes.FAILED, payload: retry.uuid });
                  errorToast("An error Occured");
                  console.error(e);
                });
            }
          }
          return;
        }

        const uniqueID = v4();
        // sending
        dispatch({
          type: ActionTypes.SEND_MESSAGE,
          payload: { uuid: uniqueID, message: msg },
        });

        if (resetForm) resetForm();
        scrollToBottom();
        http
          .post(routes.sendMessage, messageData)
          .then(() => {
            dispatch({ type: ActionTypes.SENT, payload: uniqueID });
          })
          .catch((e) => {
            dispatch({ type: ActionTypes.FAILED, payload: uniqueID });
            errorToast("An error Occured");
            console.error(e);
          });
      }
    },
    [user, scrollToBottom, adminId]
  );

  const addNewChat = useCallback(
    (newChat: { message: Chat }) => {
      if (user) {
        if (parseInt(newChat.message.to_id) === user.id) {
          if (!newChat.message.attachment.type) {
            dispatch({
              type: ActionTypes.ADD_GUEST_MESSAGE,
              payload: newChat.message.message,
            });
            if (
              newChat.message.message === "__ff_trade_closed" ||
              newChat.message.message === "__ff_trade_completed"
            ) {
              setTrade_ended(true);
            }
            scrollToBottom();
            return;
          } else {
            // When there is an attachment
            if (newChat.message.attachment.new_name) {
              dispatch({
                type: ActionTypes.ADD_GUEST_IMAGE,
                payload: newChat.message.attachment.new_name,
              });
            }
            scrollToBottom();
          }
        }
      }
    },
    [user, scrollToBottom]
  );
  const location = useLocation();
  const userLocation = useMemo(
    () => location.state as locationStateProps,
    [location]
  );

  const selectFile = () => {
    fileInputRef.current?.click();
  };

  const HandleFileSelected = useCallback(
    (imgFile: File | null, retry: boolean = false) => {
      if (imgFile) {
        const imgFileUrl = URL.createObjectURL(imgFile);
        const uniqueID = retry ? imgFile.name : v4();
        // sending
        if (retry) {
          dispatch({ type: ActionTypes.RETRY, payload: uniqueID });
        } else {
          dispatch({
            type: ActionTypes.SEND_IMAGE,
            payload: { uuid: uniqueID, message: imgFileUrl },
          });
        }
        scrollToBottom();
        const form = new FormData();
        form.append("type", "attachment");
        form.append("file", imgFile);
        form.append("message", imgFile.name);
        form.append("room_id", "testing");
        form.append("temporaryMsgId", uuidv4());
        if (user && adminId) {
          form.append("to_id", adminId.toString());
        }
        http
          .post("/messages/send", form)
          .then(() => {
            dispatch({ type: ActionTypes.SENT, payload: uniqueID });
            scrollToBottom();
          })
          .catch((err) => {
            console.error(err);
            dispatch({ type: ActionTypes.FAILED, payload: uniqueID });
            errorToast("Your message couldn't be sent. Please try again");
          });
      }
    },
    [user, adminId, scrollToBottom]
  );

  const VerifyUserLocation = useCallback(() => {
    if (typeof userLocation !== "undefined" && location.state) {
      if (userLocation.type === "sell_crypto") {
        const userDetails = userLocation.user as sell_crypto_detail;
        const txt = `These are the details: crypto: ${userDetails.crypto.name}, amount: ${userDetails.amount} ${userDetails.crypto.symbol}`;

        setAdminId(parseInt(userDetails.adminId));
        if (adminId) {
          HandleSend({ msg: "__ff_admin_joined" });
          HandleSend({ msg: txt });
        }
      } else if (userLocation.type === "giftcard") {
        const userDetails = userLocation.user as giftcard_seller_detail;

        setAdminId(parseInt(userDetails.adminId));
        const msg = `Hi foreignflip, I want to trade my $${userDetails.amount} ${userDetails.giftcard.name}`;
        if (adminId) {
          HandleSend({ msg: "__ff_admin_joined" });
          HandleSend({ msg: msg });
        }
      }
    }
  }, [userLocation, location.state, HandleSend, adminId]);

  // What does this do?
  useEffect(() => {
    VerifyUserLocation();
  }, [VerifyUserLocation]);

  const handlePayload = useCallback(
    (payload: any) => {
      if (user && payload.message.to_id === user.id.toString()) {
        addNewChat(payload);
      }
    },
    [addNewChat, user]
  );

  const { listen } = useAppSocket();

  const resendImage = useCallback(
    (uuid: string, imgSrc: string) => {
      dispatch({ type: ActionTypes.RETRY, payload: uuid });
      if (imgSrc) {
        getFileFromUrl(imgSrc, uuid).then((imgFile) => {
          HandleFileSelected(imgFile, true);
        });
      }
    },
    [HandleFileSelected]
  );
  const resendMessage = useCallback(
    (id: string, message: string, isImage: boolean) => {
      if (isImage) {
        // code to resend images, message holds the string for img src
        resendImage(id, message);
      } else {
        HandleSend({ msg: message }, undefined, {
          retry: true,
          uuid: id,
        });
      }
    },
    [HandleSend, resendImage]
  );

  useEffect(() => {
    const cleanUp = listen(handlePayload, `chat-testing`, ".messaging");
    return cleanUp;
  }, [handlePayload, listen]);

  return (
    <div className="relative max-w-2xl mx-auto">
      <div
        ref={divRef}
        className="h-[100vh] relative bg-gray-100 py-8 px-4 pt-10 overflow-y-auto"
        id="chat-bar"
      >
        <div className="bg-white z-30 flex fixed top-0 left-0 items-center justify-center w-full py-3">
          <div className="w-2 h-2 bg-green-500 mr-2 rounded-full" />
          <h1 className="text-sm">Spectrum chat</h1>
        </div>
        <div className="max-w-4xl mx-auto space-y-3 grid grid-cols-1">
          <MsgBox message="Admin Joined the Chat" type="auto" />
          {messages.map((item, index) => (
            <MsgBox
              message={item.message}
              isImageSrc={item.isImageSrc}
              type={item.type}
              key={index}
              status={item.status}
              uuid={item.uuid}
              resend={resendMessage}
            />
          ))}
          <div className="w-full h-[38px] bg-transparent" />
        </div>
      </div>
      {trade_ended && (
        <div className="absolute bottom-0 w-full flex items-center p-3 justify-between bg-gray-300 shadow-lg">
          <p className="text-center">
            This trade has been completed or closed, thanks for your patronage
          </p>
        </div>
      )}
      {!trade_ended && (
        <Formik
          initialValues={{ msg: "" }}
          onSubmit={(e, { resetForm }) => {
            HandleSend(e, resetForm);
          }}
        >
          {(formik) => (
            <>
              <div className="absolute bottom-0 w-full chatbox flex items-center p-3 justify-between bg-gray-300 shadow-lg">
                <div className="pl-1 flex w-full bg-white rounded-full shadow-lg focus:border-primary">
                  <Field
                    type="text"
                    name="msg"
                    className="flex-1 rounded-full w-4/5 p-1 text-l focus:outline-0"
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        formik.submitForm();
                      }
                    }}
                  />

                  <div
                    onClick={() => selectFile()}
                    className="flex items-center justify-center p-2 rounded-full bg-transparent hover:bg-gray-200"
                  >
                    <BsImages className="text-gray-400 text-xl" />
                    <input
                      type="file"
                      className="invisible w-0 h-0"
                      accept="image/*"
                      onChange={(e) =>
                        HandleFileSelected(
                          e.target.files ? e.target.files[0] : null
                        )
                      }
                      ref={fileInputRef}
                    />
                  </div>
                </div>

                <div className="flex px-2 gap-x-2">
                  <div
                    className="flex items-center justify-center self-end p-3 rounded-full bg-primary"
                    onClick={() => formik.submitForm()}
                  >
                    <MdSend className="text-white text-xl" />
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ChatSection;
