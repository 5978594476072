import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GiftCardValue } from "../../features/asset/thunkActions";
import LoadingPage from "../LoadingPage";
import cls from "classnames";
import { modalAction } from "../../ts/interfaces/main";
import * as Yup from "yup";
import Modal from "../PopModal";
import { Form, Formik } from "formik";
import InputField from "../inputs/InputField";
import { addAppGiftcard, removeAppGiftcard } from "../../features/asset/slice";
import { errorToast, successToast } from "../../app/toasts";
import http from "../../utils/http";
import GiftcardBox from "../GiftcardBox";

const GiftCard = () => {
  const [openAddGiftCard, setOpenAddGiftCard] = useState<boolean>(false);
  const [giftcard, setGiftCard] = useState<GiftCardValue | undefined>(
    undefined
  );
  const { loading, data } = useAppSelector((state) => state.giftcards);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [giftcardToDelete, setGiftCardToDelete] = useState<
    GiftCardValue | undefined
  >(undefined);

  return (
    <>
      <AnimatePresence>
        {openAddGiftCard && (
          <AddGiftCard
            giftcard={giftcard}
            onClose={() => {
              setGiftCard(undefined);
              setOpenAddGiftCard(false);
              // add network to store
            }}
          />
        )}
        {showDelete && giftcardToDelete && (
          <DeleteGiftCard
            giftcard={giftcardToDelete}
            onClose={() => {
              setGiftCardToDelete(undefined);
              setShowDelete(false);
              // remove network from store
            }}
          />
        )}
      </AnimatePresence>
      <div className="flex flex-wrap space-x-4 items-center">
        <h2 className="font-bold text-[32px]">Giftcards</h2>
        <button
          onClick={() => setOpenAddGiftCard(true)}
          className="bg-[#b712d8] hover:bg-primary my-2 text-white py-2 px-4 rounded text-sm md:text-base"
        >
          Add giftcard
        </button>
      </div>
      <div
        className={cls(
          "flex gap-3 my-3 items-center flex-wrap",
          { "gap-x-10": data.length < 3 },
          { "justify-between": data.length >= 3 }
        )}
      >
        {loading ? (
          <LoadingPage />
        ) : (
          data &&
          data.map((item) => (
            <GiftcardBox
              key={item.id.toString()}
              giftcard={item}
              editable
              onDelete={() => {
                setGiftCardToDelete(item);
                setShowDelete(true);
              }}
            />
          ))
        )}
        {!loading && data.length === 0 && (
          <p className="text-gray-600 mt-3">
            No giftcard available, Add a new giftcard
          </p>
        )}
      </div>
    </>
  );
};

export default GiftCard;

interface AddgiftcardProps extends modalAction {
  giftcard?: GiftCardValue;
}

const AddGiftcardSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
});

const AddGiftCard = ({ giftcard, onClose }: AddgiftcardProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const addGiftCard = ({ name }: { name: string }) => {
    setLoading(true);
    const data = new FormData();
    data.append("name", name);
    if (imgFile) data.append("logo", imgFile, imgFile.name);
    const onSuccess = (data: any) => {
      setLoading(false);
      dispatch(addAppGiftcard(data));
      successToast(`${name} has been added successfully`);
      onClose();
    };
    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured, please try again");
    };
    http
      .post("/giftCards/create", data)
      .then((data: any) => {
        onSuccess(data);
      })
      .catch(onFailure);
  };
  return (
    <Modal
      title={giftcard ? "Edit Giftcard" : `Add New Giftcard`}
      innerDivClassName="mt-6"
      onClose={onClose}
    >
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <Formik
          validationSchema={AddGiftcardSchema}
          initialValues={{
            name: "",
          }}
          onSubmit={(e: { name: string }) => {
            if (imgFile) {
              if (fileError) setFileError(false);
              addGiftCard(e);
            } else {
              setFileError(true);
            }
          }}
        >
          {() => (
            <Form className="w-full space-y-4">
              <InputField
                name="name"
                placeholder="Name"
                label="GiftCard Name"
              />
              <div className="">
                <p className="text-sm font-semibold mb-3">Upload Logo</p>
                <input
                  type={"file"}
                  onChange={(event) => {
                    if (event.currentTarget.files)
                      setImgFile(event.currentTarget.files[0]);
                  }}
                />
                {fileError && (
                  <p className="text-sm text-red-600">
                    Logo has not been selected
                  </p>
                )}
              </div>

              {loading ? (
                <LoadingPage />
              ) : (
                <div className="flex space-x-4 items-center justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-primary bg-transparent py-2 px-4 rounded "
                  >
                    Cancel
                  </button>
                  <button
                    type={"submit"}
                    className="bg-primary text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
interface DeleteGiftCardProps extends modalAction {
  giftcard: GiftCardValue;
}
const DeleteGiftCard = ({ onClose, giftcard }: DeleteGiftCardProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const giftcardDelete = () => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      successToast(`${giftcard.name} Deleted"`);
      dispatch(removeAppGiftcard(giftcard));
      onClose();
    };

    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured, please try again");
    };

    http
      .get(`/giftCards/delete/${giftcard.id}`)
      .then(onSuccess)
      .catch(onFailure);
  };
  return (
    <Modal title={"Delete Network"} innerDivClassName="mt-6" onClose={onClose}>
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        <p className="mb-4">Are you sure you want to delete {giftcard.name}?</p>
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="flex space-x-4 items-center justify-end">
            <button
              type="button"
              onClick={onClose}
              className="text-primary bg-transparent py-2 px-4 rounded "
            >
              Cancel
            </button>
            <button
              type={"submit"}
              onClick={giftcardDelete}
              className="bg-primary text-white py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
