import { formatDistance } from "date-fns";
import { MsgProps } from "../components/utility/MsgBox";
// import http from "./http";

export const formatDate = (timeStamp: string) => {
  return formatDistance(new Date(timeStamp), new Date(), { addSuffix: true });
};

export const tokenImageDownloader = () => {
  // return `https://foreignflip.com/storage/token-images/${imageName}`;
  return "https://www.flaticon.com/free-icon/done_7799536";

  // http.get(`/download/${imageName}`).then().catch((e) => console.error("Faiiled to download image") )
};

export const giftCardImageDownloader = () => {
  return;
  // http.get(`/download/${imageName}`).then().catch((e) => console.error("Faiiled to download image") )
};

export const sortByDate = <T>(data: T[], order: "oldest" | "latest") => {
  if (order === "latest") {
    return data.sort((a: any, b: any) => {
      return (
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    });
  } else {
    return data.sort((a: any, b: any) => {
      return (
        new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      );
    });
  }
};

export const CurrencyFormat = (num: number, decimalPlaces?: number) => {
  if (decimalPlaces) {
    return num.toFixed(decimalPlaces).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  return num.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const NumberToString = (num: number) => {
  return num.toString();
};

export const FindDecimal = (num: string | number) => {
  if (typeof num === "number") {
    FindDecimal(num.toString());
  } else {
    if (num.includes(".")) {
      const decimalPlaces = num.split(".")[1].length;
      return decimalPlaces;
    }
  }
  return 0;
};

export const findIndex = (uuid: string, array: MsgProps[]) => {
  return array.findIndex((i) => i.uuid === uuid);
};

export async function getFileFromUrl(
  url: string,
  name: string,
  defaultType = "image/jpeg"
) {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}
