import React from "react";
import { Formik } from "formik";
import InputField from "../inputs/InputField";

const imgSrc =
  "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png";

const Profile = () => {
  return (
    <>
      <h2 className="font-bold text-[32px]">Profile</h2>
      <div className="mt-10 flex lg:w-[453px] gap-5 items-end">
        <img src={imgSrc} className="w-[160px]" alt="" />
        <div className="gap-3">
          <input type="file" className="w-0 h-0" />
          <button className="bg-transparent border border-[#4C4C4C] text-[#4C4C4C] py-3 px-5 rounded">
            Choose a File
          </button>
          <p className="text-[#4c4c4cdc] text-xs mt-2">
            Acceptable formats: jpg, png, gif only. Max file size is 500kb
          </p>
        </div>
      </div>
      <div className="mt-12">
        <div className="flex items-center justify-between pr-6 mt-5">
          <h2 className="font-bold text-center text-[20px] text-[#4c4c4c]">
            Change Password
          </h2>
        </div>
        <Formik
          onSubmit={(e) => console.log(e)}
          initialValues={{
            password: "",
            newPassword: "",
          }}
        >
          <>
            <div className="flex my-2">
              <div className="flex-1 flex items-center justify-between pr-6">
                <p className="text-[#4c4c4c]">Current Password</p>
                <InputField
                  name="password"
                  type={"password"}
                  placeholder="John"
                  parentClassName="w-8/12"
                />
              </div>
              <div className="flex-1 flex items-center justify-between pr-6">
                <p className="text-[#4c4c4c]">New Password</p>
                <InputField
                  name="newPassword"
                  type={"password"}
                  placeholder="New Password"
                  parentClassName="w-8/12"
                />
              </div>
            </div>

            <div className="mt-4 flex items-center justify-center">
              <button className="bg-primary text-white py-2 px-4 rounded ">
                Update Password
              </button>
            </div>
          </>
        </Formik>
      </div>
    </>
  );
};

export default Profile;
