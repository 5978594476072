import React, { useState } from "react";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import { Field, ErrorMessage } from "formik";

interface PasswordProps {
  name: string;
  placeholder?: string;
  label?: string;
}

const PasswordInputField: React.FC<PasswordProps> = ({
  name,
  label,
  placeholder,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const togglePasswordVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div>
      <label className="text-sm font-semibold">{label}</label>
      <div className="inline-flex w-full mt-1 items-center space-x-2 rounded-md bg-white border border-gray-300 focus-within:border-blue-500">
        <Field
          name={name}
          type={isHidden ? "password" : "text"}
          placeholder={placeholder}
          className="py-3 pl-3 focus:outline-none text-sm w-full text-gray-900"
        />
        <button
          className="rounded-md px-1 pr-3 block"
          type={"button"}
          onClick={togglePasswordVisibility}
        >
          {isHidden ? <RiEyeCloseLine /> : <RiEyeLine />}
        </button>
      </div>
      <ErrorMessage
        name={name}
        render={(err) => <p className="text-red-500 text-sm">{err}</p>}
      />
    </div>
  );
};

export default PasswordInputField;
