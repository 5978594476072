import React, { Fragment, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import SearchBox from "../../components/utility/SearchBox";
import InfoPane from "./InfoPane";
import { useTable, usePagination } from "react-table";
import useModal from "../../components/Modal/useModal";
import ViewTransactionModal from "../../components/Admin/ViewTransactionModal";
import type { Trade, TradeStatus } from "../../state/types";
import { formatDate } from "../../utils";
import { getTradeStatusColor } from "../../components/utility";
// import IndeterminateCheckbox from "../../components/CheckBox/IndeterminateCheckbox";
import Dropdown from "../../components/Menu/Dropdown";
import { hideTradeExcept, sortTrade } from "../../features/contacts/slice";
import classNames from "classnames";

type Accessor =
  | "crypto"
  | "currency"
  | "amount"
  | "price"
  | "status"
  | "type"
  | "id"
  | "tradeReference"
  | "paid"
  | "released"
  | "updated_at";

interface ColumnProps {
  Header: string;
  accessor: Accessor;
}

const Transactions = () => {
  const {
    isLoading: tradeLoading,
    visibleTrades: tradeData,
    sort,
    showOnly,
  } = useAppSelector((state) => state.trades);
  const [selectedTrade, setselectedTrade] = useState<Trade>();
  const dispatch = useAppDispatch();

  const columns: ColumnProps[] = useMemo(
    () => [
      {
        Header: "Asset",
        accessor: "crypto",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Quantity",
        accessor: "amount",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Paid",
        accessor: "paid",
      },
      {
        Header: "Time",
        accessor: "updated_at",
      },
      {
        Header: "",
        accessor: "id",
      },
      {
        Header: "",
        accessor: "released",
      },
      {
        Header: "",
        accessor: "tradeReference",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: tradeData,
      initialState: { pageIndex: 0 },
      autoResetPage: false,
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // selectedFlatRows,
    state: { pageIndex, pageSize /* , selectedRowIds */ },
  } = tableInstance;

  const [presentViewTransactionModal] = useModal(
    <ViewTransactionModal trade={selectedTrade!} />,
    true,
    true
  );

  const renderTableControls = () => (
    <div className="mt-3 flex flex-col items-center">
      <div className="my-2">
        <ul className="flex p-1 list-none w-full">
          <ListItem>
            <button
              className="py-1 px-2 text-sm rounded-md disabled:cursor-not-allowed disabled:opacity-40 hover:bg-primary/20 underline"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              First
            </button>
          </ListItem>
          <ListItem>
            <button
              className="py-1 px-2 text-sm rounded-md disabled:cursor-not-allowed disabled:opacity-40 hover:bg-primary/20 underline"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
          </ListItem>
          <ListItem>
            <button
              className="py-1 px-2 text-sm rounded-md disabled:cursor-not-allowed disabled:opacity-40 hover:bg-primary/10 underline"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
          </ListItem>
          <ListItem>
            <button
              className="py-1 px-2 text-sm rounded-md disabled:cursor-not-allowed disabled:opacity-40 hover:bg-primary/10 underline"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              Last
            </button>
          </ListItem>
        </ul>
      </div>
      <div className="text-xs text-gray-500">
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            className="w-10 border-b outline-none border-gray-500 px-1 rounded-sm bg-blue-50"
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className="w-20 border-b outline-none border-gray-500 bg-blue-50 rounded-sm"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const status: (TradeStatus | "all")[] = [
    "all",
    "pending",
    "completed",
    "approved",
    "closed",
    "declined",
  ];

  return (
    <InfoPane>
      <div className="flex items-center justify-between mt-8">
        <div className="">
          <h1 className="text-lg font-semibold">All Transactions</h1>
          <p className="text-xs text-[#B3B3B3]">Monitor all trades.</p>
        </div>
        <div className="flex w-2/4 gap-2">
          {/* <SearchBox
            placeholder="Search  by ID, crypto, currency or giftcard"
            type="dashboard"
            name="search"
          /> */}
          <div className="w-full" />
          <Dropdown heading="Sort trades">
            <div className="py-1">
              <button
                onClick={() =>
                  dispatch(sortTrade(sort === "latest" ? "oldest" : "latest"))
                }
                tabIndex={0}
                className="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left capitalize"
                role="menuitem"
              >
                {sort}
              </button>
              {status.map((stat) => (
                <button
                  key={stat}
                  onClick={() => dispatch(hideTradeExcept(stat))}
                  className={classNames(
                    "text-gray-700 flex justify-between w-full px-4 py-2 text-xs leading-5 text-left capitalize",
                    stat === showOnly && "bg-primary/10"
                  )}
                  role="menuitem"
                >
                  {stat}
                </button>
              ))}
            </div>
          </Dropdown>
        </div>
      </div>
      {tradeLoading ? (
        <div className="text-base text-center my-5 ">
          Fetching transactions...
        </div>
      ) : (
        tradeData.length > 0 && (
          <Fragment>
            {renderTableControls()}
            <div className="overflow-x-auto mt-10">
              <table className="table-auto w-full" {...getTableProps()}>
                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          scope="col"
                          {...column.getHeaderProps()}
                          className="p-2"
                        >
                          <div className="font-semibold text-left">
                            {column.render("Header")}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="text-sm divide-y divide-gray-100 text-gray-600 font-normal"
                >
                  {page.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={() => {
                            setselectedTrade(row.values as Trade);
                            presentViewTransactionModal();
                          }}
                          key={row.id}
                          className="cursor-pointer hover:bg-gray-50/70 transition-colors duration-200 md:py-3"
                        >
                          {row.cells.map((cell) => {
                            if (cell.column.id === "crypto") {
                              return (
                                <td {...cell.getCellProps()} className="p-3">
                                  {cell.value.name}
                                </td>
                              );
                            }
                            if (cell.column.id === "currency") {
                              return (
                                <td {...cell.getCellProps()} className="p-3">
                                  {cell.value.symbol}
                                </td>
                              );
                            }
                            if (cell.column.id === "status") {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="p-3"
                                  style={{
                                    color: getTradeStatusColor(cell.value),
                                  }}
                                >
                                  {cell.value}
                                </td>
                              );
                            }
                            if (cell.column.id === "price") {
                              return (
                                <td {...cell.getCellProps()} className="p-3">
                                  <div className="text-left font-medium text-green-500">
                                    {cell.value}
                                  </div>
                                </td>
                              );
                            }
                            if (cell.column.id === "paid") {
                              const paid = cell.value;
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="p-3"
                                  style={{
                                    color: getTradeStatusColor(
                                      paid ? "pending" : "closed"
                                    ),
                                  }}
                                >
                                  {paid ? "Yes" : "No"}
                                </td>
                              );
                            }

                            if (cell.column.id === "updated_at") {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="p-3 text-xs"
                                >
                                  {formatDate(cell.value)}
                                </td>
                              );
                            }
                            if (cell.column.id === "id") {
                              return null;
                            }

                            if (cell.column.id === "released") {
                              return null;
                            }
                            if (cell.column.id === "tradeReference") {
                              return null;
                            }
                            return (
                              <td {...cell.getCellProps()} className="p-3">
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      {new Array(6).fill("h").map((_e, i) => (
                        <td
                          key={i}
                          className="w-full h-10 bg-gray-300 border-x-2 border-white animate-pulse"
                        >
                          wait...
                        </td>
                      ))}
                    </tr>
                  )}
                </tbody>
              </table>
              {renderTableControls()}
            </div>
          </Fragment>
        )
      )}
      {!tradeLoading && tradeData.length === 0 && (
        <p className="text-center mt-3">There are no trades available</p>
      )}
    </InfoPane>
  );
};

const ListItem = (props: { children: React.ReactNode }) => (
  <li className="relative block bg-white text-primary ml-1 !underline">
    {props.children}
  </li>
);

export default Transactions;
