
import React from "react";//{useEffect,useState}
import AccountHeader from "../components/AccountHeader";
import Footer from "../components/Home/Footer";
import Transactions from "../components/Home/Transactions";

import img1 from "../utils/airtime-mobile.png";
import img2 from "../utils/data.png";
import img3 from "../utils/sport-betting.png";
import img4 from "../utils/tv.png";
import img5 from "../utils/electricity.png";
import img6 from "../utils/internet.png";
import img7 from "../utils/refer-and-earn.png";
import img8 from "../utils/more.png";
import img9 from "../utils/add.png";
import img10 from "../utils/send.png";
import img11 from "../utils/withdraw.png";
import img12 from "../utils/profile.png";
import img13 from "../utils/scan.png";
import img14 from "../utils/headset.png";
import img15 from "../utils/notification.png";
//import axios from "axios";
// import {  useAppSelector } from "../app/hooks";
// import axios from "../utils/http";


const Home3 = () => {
  // const user = useAppSelector((state) => state.user);
  // const slides = [img1, img2, img3, img4, img5, img6];
  // const slides = [
  //   "https://i.ibb.co/ncrXc2V/1.png",
  //   "https://i.ibb.co/B3s7v4h/2.png",
  //   "https://i.ibb.co/XXR8kzF/3.png",
  //   "https://i.ibb.co/yg7BSdM/4.png",
  // ];
  //const name = (user.data?.name);
//   const datas = [user.data];
//   let newdata = JSON.stringify(datas)
//   let arrData = JSON.parse(newdata)
//   const email = (arrData[0].email)

// const [balance, setBalance] = useState('');
// const [name, setName] = useState('');
// const GetBalance = async () => {
//   const users  = await axios.get('/api/v1/spectrum/admin/findByemail/'+email);
//   let b1 = JSON.stringify(users)
//   let b2 = JSON.parse(b1)
//   setBalance(b2.balance)
//   setName(b2.name)
// }
// useEffect(  ()  => {
//   GetBalance()
// }, []);
 


  return (
    <div>
      {/* Start of hero */}
      <AccountHeader />

      <div className="flex md:ml-20 md:mr-20 justify-between m-4">
        <div className="flex flex-2 justify-center items-center">
          <img className="md:w-30 w-16" src={img12} alt="Airtime" />
         
            {/* <p className=" text-black text-sm -mb-2 md:mb-1">Hi, {name} </p> */}
          <p className=" text-black text-sm -mb-2 md:mb-1">Hi,  James Peter</p>
        </div>

        <div className="flex flex-1 justify-end ml-6 items-center ">
          <img className="md:w-6 w-6 h-6" src={img14} alt="Airtime" />
          <img className="md:w-6 w-6 h-6 m-2" src={img13} alt="Airtime" />
          <img className="md:w-6 w-6 h-6" src={img15} alt="Airtime" />
        </div>
      </div>

      <div className="bg-gradient-to-br from-[#ffffff] to-[#ffffff] pt-12 ">
        <div className="md:ml-20 md:mr-20 ml-3 mr-3 -mt-16 ">
          <div className="flex flex-col lg:flex-row pl-6 pr-6  justify-between shadow-lg rounded-xl md:p-16 p-4 pt-5 bg-[#00B776]">
            <div className="flex-1">
              <div className="flex justify-between mb-3">
                <p className=" text-white text-xs -mb-2 md:mb-1">
                  Available Balance:
                </p>

                <p className=" text-white text-xs -mb-2 md:mb-1">
                  Transaction History{" >"}
                </p>
              </div>

              <h1 className="text-white drop-shadow-md text-[30px] md:text-[60px] leading-[55px] font-semibold">
                {/* ${balance}.00 */}
              </h1>

              {/* <h1 className="text-white drop-shadow-md text-[30px] md:text-[60px] leading-[55px] font-semibold">
                $9,000.00
              </h1> */}
              <p className=" text-white text-[10px] mt-1 md:mb-1">
                & Cashback ₦1500
              </p>

              <div className="flex flex-row overflow-y-auto md:overflow-x-hidden items-center md:justify-start mt-4 justify-between gap-y-4 md:flex-row space-x-2 md:space-x-5">
                <div className="flex flex-col justify-center items-center">
                  <button className="bg-white text-[#1d541c] font-semibold text-lg px-1 py-1 rounded-lg">
                    <img className="md:w-6 w-6" src={img9} alt="Airtime" />
                  </button>
                  <p className=" text-white text-[10px] mt-1 md:mb-1">
                    Add Money
                  </p>
                </div>

                <div className="flex flex-col justify-center items-center">
                  <button className="bg-white text-[#1d541c] font-semibold text-lg px-1 py-1 rounded-lg">
                    <img className="md:w-6 w-6" src={img10} alt="Airtime" />
                  </button>
                  <p className=" text-white text-[10px] mt-1 md:mb-1">
                    Transfer
                  </p>
                </div>

                <div className="flex flex-col justify-center items-center">
                  <button className="bg-white text-[#1d541c] font-semibold text-lg px-1 py-1 rounded-lg">
                    <img className="md:w-6 w-6" src={img11} alt="Airtime" />
                  </button>
                  <p className=" text-white text-[10px] mt-1 md:mb-1">
                    withdraw
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of hero */}

      <div className="flex flex-col lg:flex-row pl-6 pr-6 md:ml-20 md:mr-20  justify-between shadow-lg rounded-xl md:p-16 p-4 pt-5 m-4 bg-white">
        <div className="flex-1">
          <div className="flex flex-row md:justify-around overflow-y-auto md:overflow-x-hidden items-center mt-4 justify-between gap-y-4 md:flex-row space-x-2 md:space-x-5">
            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8" src={img1} alt="Airtime" />
              </button>

              <p className=" text-black text-[10px] mt-1 md:mb-1">Airtime</p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8" src={img2} alt="Airtime" />
              </button>
              <p className=" text-black text-[10px] mt-1 md:mb-1">Data</p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8" src={img3} alt="Airtime" />
              </button>
              <p className=" text-black text-[10px] mt-1 md:mb-1">Betting</p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8" src={img4} alt="Airtime" />
              </button>
              <p className=" text-black text-[10px] mt-1 md:mb-1">Tv</p>
            </div>
          </div>
        </div>

        <div className="flex-1">
          <div className="flex flex-row overflow-y-auto md:overflow-x-hidden md:justify-around  items-center  mt-4 justify-between gap-y-4 md:flex-row space-x-2 md:space-x-5">
            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8" src={img5} alt="Airtime" />
              </button>
              <p className=" text-black text-[10px] mt-1 md:mb-1">
                {" "}
                Electricity
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8" src={img6} alt="Airtime" />
              </button>
              <p className=" text-black text-[10px] mt-1 md:mb-1">Internet</p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8" src={img7} alt="Airtime" />
              </button>
              <p className=" text-black text-[10px] mt-1 md:mb-1">Refer&Earn</p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <button className="bg-[#E1F6EA] text-[#1d541c] font-semibold text-lg px-2 py-1 rounded-full">
                <img className="md:w-6 w-8 " src={img8} alt="Airtime" />
              </button>
              <p className=" text-black text-[10px] mt-1 md:mb-1">More</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row pl-6 pr-6 md:ml-20 md:mr-20 items-center shadow-lg rounded-xl md:p-16 p-4 pt-5 m-4 bg-white">
        <img className="md:w-6 w-10 mr-4" src={img7} alt="Airtime" />
        <div>
          <p className=" text-black text-[14px] ">Refer and Earn</p>
          <p className=" text-black text-[10px] ">
            Earn #800 Cashback per referral
          </p>
        </div>
      </div>
      <div className="flex lg:flex-row pl-6 pr-6 md:ml-20 md:mr-20 items-center shadow-lg rounded-xl md:p-16 p-4 pt-5 m-4 bg-white">
        <img className="md:w-6 w-10 mr-4" src={img2} alt="Airtime" />
        <div>
          <p className=" text-black text-[14px] ">Buy Airtime and Data</p>
          <p className=" text-black text-[10px] ">
            Earn #800 Cashback per referral
          </p>
        </div>
      </div>
      <Transactions />

      <Footer />
    </div>
  );
};

export default Home3;
