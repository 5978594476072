import { Form, Formik } from "formik";
import React, { useState } from "react";
import InputField from "../inputs/InputField";
import LoadingPage from "../LoadingPage";
import Modal from "../PopModal";
import * as Yup from "yup";
import { errorToast, successToast } from "../../app/toasts";
import http from "../../utils/http";
import { modalAction } from "../../ts/interfaces/main";
import { useAppDispatch } from "../../app/hooks";
import { addAppCrypto } from "../../features/asset/slice";

export interface addCrpytoTypes {
  name: string;
  buy_rate: number;
  sell_rate: number;
  symbol: string;
}
const AddCryptoSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  buy_rate: Yup.number().min(10).required(),
  sell_rate: Yup.number().min(10).required(),
  symbol: Yup.string().min(2).required(),
});

const AddCrypto = ({ onClose }: modalAction) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const addCrpyto = (values: addCrpytoTypes) => {
    setLoading(true);
    const data = new FormData();
    data.append("name", values.name);
    data.append("buy_rate", values.buy_rate.toString());
    data.append("sell_rate", values.sell_rate.toString());
    data.append("symbol", values.symbol);
    if (imgFile) data.append("logo", imgFile, imgFile.name);
    const onSuccess = (data: any) => {
      setLoading(false);
      dispatch(addAppCrypto(data));
      successToast(`${values.name} has been added successfully`);
      onClose();
    };
    const onFailure = () => {
      setLoading(false);
      errorToast("An error occured, please try again");
    };
    http
      .post("/crypto/create", data)
      .then((data: any) => {
        onSuccess(data);
      })
      .catch(onFailure);
  };
  return (
    <Modal
      title={`Add New Cryptocurrency`}
      innerDivClassName="mt-6"
      onClose={onClose}
    >
      <div className=" flex flex-col justify-center items-center mt-[2rem] pb-[5rem]">
        {loading ? (
          <LoadingPage />
        ) : (
          <Formik
            validationSchema={AddCryptoSchema}
            initialValues={{
              name: "",
              buy_rate: 0,
              sell_rate: 0,
              symbol: "",
            }}
            onSubmit={(e) => {
              if (imgFile) {
                if (fileError) setFileError(false);
                addCrpyto(e);
              } else {
                setFileError(true);
              }
            }}
          >
            {() => (
              <Form className="w-full space-y-4">
                <InputField name="name" placeholder="Name" label="Name" />
                <div className="flex items-center justify-between gap-x-3">
                  <InputField
                    name="buy_rate"
                    type={"number"}
                    placeholder="Buy Price"
                    label="Buy Price"
                  />
                  <InputField
                    name="sell_rate"
                    type={"number"}
                    placeholder="Sell Price"
                    label="Sell Price"
                  />
                </div>
                <InputField
                  name="symbol"
                  placeholder="Symbol eg. BTC"
                  label="Symbol"
                />
                <div className="">
                  <p className="text-sm font-semibold mb-3">Upload Logo</p>
                  <input
                    type={"file"}
                    onChange={(event) => {
                      if (event.currentTarget.files)
                        setImgFile(event.currentTarget.files[0]);
                    }}
                  />
                  {fileError && (
                    <p className="text-sm text-red-600">
                      Logo has not been selected
                    </p>
                  )}
                </div>
                <div className="flex space-x-4 items-center justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-primary bg-transparent py-2 px-4 rounded "
                  >
                    Cancel
                  </button>
                  <button
                    type={"submit"}
                    className="bg-primary text-white py-2 px-4 rounded"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};

export default AddCrypto;
