import type { Trade } from "../../state/types";

export const getTradeStatusColor = (status: Trade["status"] | "all") => {
  switch (status) {
    case "approved":
      return "blue";
    case "closed":
      return "red";
    case "completed":
      return "gray";
    case "pending":
      return "#daa10c";
    case "declined":
      return "red";
    default:
      return "black";
  }
};
