export const NavLinks = [
  {
    name: "Home",
    link: "/",
   
  },
  {
    name: "About Us",
    link: "/aboutus",
   
  },
  // {
  //   name: "FAQ",
  //   link: "/#faq",
   
  // },
  // {
  //   name: "Contact Us",
  //   link: "/#contactus",
    
  // },
];

export const testmomies = [
  {
    title:"The best place to sell your houses",
    img:"../utils/user.png",
    message:"Absolutely no fees were paid! We recently put our house up for sale on amazing properties and we received a fair cash offer for our house. Their procedures were quick, simple and effective, saving us a ton of labour. Amazing properties comes highly recommended.",
    location:"Columbus, Ohio",
    fullname:'Paul Freeman'
  },
  {
    title:"Amazing Properties is the #1",
    img:"../utils/user.png",
    message:"I am extremely appreciative of the assistance in putting me in touch with the agent and supporting me throughout the closing. It was a difficult process overall, so I m delighted to have a solid support. Thanks.",
    location:"Winchester city, Ohio",
    fullname:'Douglas'
  },
  {
    title:"Very easy to use and sell properties",
    img:"../utils/user.png",
    message:"The deal was excellent. For as-is sale and cleanup, their offer was excellent. Every step of the procedure was made easier by their staff. Would undoubtedly work with Amazing Properties once more.",
    location:"Grove city, Ohio",
    fullname:'Jackson'
  }
  ,

]


