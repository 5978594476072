import classNames from "classnames";
import React from "react";

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties | undefined;
};

const Container = ({ children, className, style }: ContainerProps) => {
  const classStyle = classNames("w-10/12 lg:w-[85%] my-0  mx-auto", className);
  return (
    <section className={classStyle} style={style}>
      {children}
    </section>
  );
};

export default Container;
