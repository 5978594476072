import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";

const currentYear = new Date().getFullYear();

const InfoPane = ({
  children,
  extraInfo,
}: {
  children: React.ReactNode;
  extraInfo?: React.ReactNode;
}) => {
  const location = useLocation();
  return (
    <>
      <div
        className={classNames(
          "md:flex-[3] relative h-full md:h-screen overflow-auto",
          {
            "px-[30px] md:px-[60px] py-[60px]":
              !location.pathname.includes("/admin/chat"),
          }
        )}
      >
        {children}
        <div className="fixed bg-white p-1 w-full bottom-0 left-0">
          <p className="hidden md:block text-center text-xs relative left-[7rem]">
            copyright Spectrum {currentYear}. Privacy | Terms and Conditions
          </p>
        </div>
      </div>
      {/* Right pane */}
      {extraInfo && <div className="flex-1 md:hidden">{extraInfo}</div>}
    </>
  );
};

export default InfoPane;
