import React from 'react'
import Container from '../utility/Container'
import giftCards from '../../assets/img/giftCards.png'

const DiscoverMore = () => {
  return (
    <section className='w-full py-[60px] bg-no-repeat bg-cover'>
      <Container>
        <div className="flex flex-col lg:flex-row-reverse items-center justify-center space-y-3">
          <div className='lg:flex-[0.4] flex items-center justify-center'>
          <img src={giftCards} className='' alt="we also trade gift cards" />

          </div>
          <div className='lg:flex-[0.6]'>
            <h1 className='font-bold text-3xl md:text-[48px] text-center mx-auto lg:mx-0  lg:text-left lg:w-[70%] py-5 text-primary md:leading-[50px]'>Discover more when you trade with us.</h1>
            <p className='text-base md:leading-[34px] md:text-xl text-center mt-5 lg:text-left'>We buy your btc, eth, usdt, gift cards and store cards in bulk. Foreignflip gives the possibilities for instant payments. Secure and Reliable</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default DiscoverMore