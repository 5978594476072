import { createAsyncThunk } from "@reduxjs/toolkit";
import type { GiftCardTrade } from "../../state/types";
import type { ReduxAction } from "../../ts/interfaces/redux";
import http from "../../utils/http";

export const getGiftCardTrades = createAsyncThunk(
  "admin/trade/giftCards",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const data: any = await http.get("trades/giftCards");
      if (onSuccess) onSuccess(data.trades);
      return data.trades.sort((a: GiftCardTrade, b: GiftCardTrade) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
