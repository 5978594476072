import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { modalAction } from "../../ts/interfaces/main";
import PopModal from "../PopModal";
type WhatsAppModalProps = {
  type: "buy" | "sell" | "giftcard";
};

const generateLinks = ({ type }: WhatsAppModalProps) => {
  if (type === "giftcard") {
    return {
      whatsapp:
        "https://api.whatsapp.com/send?phone=2347083925376&text=Hello%Spectrum,%20I%20want%20to%20trade%20my%20gift%20card",
      website: "/sell/giftcard",
    };
  }

  if (type === "sell") {
    return {
      whatsapp:
        "https://api.whatsapp.com/send?phone=2347083925376&text=Hello%Spectrum,%20I%20want%20to%20trade%20my%20crypto",
      website: "/crypto/sell",
    };
  }

  if (type === "buy") {
    return {
      whatsapp:
        "https://api.whatsapp.com/send?phone=2347083925376&text=Hello%Spectrum,%20I%20want%20to%20trade%20my%20crypto",
      website: "/crypto/buy",
    };
  }

  return {
    whatsapp: "",
    website: "",
  };
};

const WhatsAppModal = ({ type, onClose }: WhatsAppModalProps & modalAction) => {
  return (
    <PopModal title="" onClose={onClose}>
      <h2 className="text-center font-semibold">
        Would you like to continue on WhatsApp?
      </h2>
      <div className="p-4 flex gap-y-5 flex-col items-center justify-center">
        <a
          href={generateLinks({ type }).whatsapp}
          className="flex w-full max-w-[265px] py-3 rounded-lg text-white items-center justify-center gap-x-4 bg-[#25D366]"
        >
          <BsWhatsapp color="white" size={25} />{" "}
          <span className="text-lg">Yes, Proceed</span>{" "}
        </a>
        <Link
          to={generateLinks({ type }).website}
          className="flex w-full max-w-[265px] items-center justify-center gap-x-4 bg-white text-gray-400 "
        >
          Continue on Website
        </Link>
      </div>
    </PopModal>
  );
};

export default WhatsAppModal;
