import React, { useState } from "react";
import cls from "classnames";
import { useAppSelector } from "../../app/hooks";
import LoadingPage from "../LoadingPage";
import { FaUsers, FaUsersSlash } from "react-icons/fa";
import classNames from "classnames";

type userType = {
  email: string;
  tradeStatus: "completed" | "abandoned" | "ongoing";
};
export const demoUsers: userType[] = [
  {
    email: "demo@demo.com",
    tradeStatus: "completed",
  },
  {
    email: "userx@demo.com",
    tradeStatus: "ongoing",
  },
  {
    email: "chibuike@demo.com",
    tradeStatus: "abandoned",
  },
  {
    email: "bakare@demo.com",
    tradeStatus: "completed",
  },
  {
    email: "sweedish@demo.com",
    tradeStatus: "completed",
  },
];
export interface Contact {
  id: number;
  username: string;
  email: string;
  name: string;
  phone: string | null;
  image: string | null;
  active_status: number;
  password: string;
  email_verified_at: string | null;
  remember_token: string;
  created_at: string;
  updated_at: string;
  max_created_at: string;
}
interface UserListProps {
  selectedContact?: Contact | null;
  setSelectedContact?: React.Dispatch<
    React.SetStateAction<Contact | undefined>
  >;
}
const UserList = ({ selectedContact, setSelectedContact }: UserListProps) => {
  const { isLoading, data } = useAppSelector((state) => state.contactInfo);
  const [active, setActive] = useState(false);
  // const status = ["declined", "pending", "completed"];

  return (
    <div className="flex flex-col pt-[40px] h-full">
      <div className="w-full px-6 flex items-center justify-between">
        <div>
          <h2 className="font-semibold text-xl text-black">Recent Contacts</h2>
        </div>
        <div className="md:hidden">
          {active ? (
            <FaUsersSlash
              onClick={() => {
                setActive(false);
              }}
              size={40}
            />
          ) : (
            <FaUsers
              onClick={() => {
                setActive(true);
              }}
              size={40}
            />
          )}
        </div>
      </div>
      <div
        className={classNames(
          { block: active },
          { hidden: !active },
          "transition-all duration-[0.5s] md:!block"
        )}
      >
        {isLoading ? (
          <LoadingPage />
        ) : data.contacts ? (
          data.contacts.map((item) => (
            <div
              className={cls(
                "w-full cursor-pointer flex my-1 p-2 items-center justify-start space-x-3",
                { "bg-purple-300": item.id === selectedContact?.id }
              )}
              key={item.email}
              onClick={() => {
                if (setSelectedContact) {
                  setSelectedContact(item);
                  if (active) setActive(false);
                }
              }}
            >
              <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary">
                <h1 className="text-xl text-white uppercase">
                  {item.email.slice(0, 2)}
                </h1>
              </div>
              <div className="flex flex-col gap-y-1">
                <h4 className="font-semibold">
                  {item.email.length > 25
                    ? item.email.slice(0, 23) + "..."
                    : item.email}
                </h4>
                {/* <p
                className={
                  item.active_status === 2
                    ? "text-green-700"
                    : item.active_status === 1
                    ? "text-yellow-600"
                    : "text-gray-500"
                }
              >
                {status[item.active_status]}
              </p> */}
              </div>
            </div>
          ))
        ) : null}
        {!isLoading && data.contacts?.length === 0 && (
          <div className="h-full flex items-center justify-center">
            <h1 className="text-center -top-10">No chat available</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
