import React, { useState } from "react";
import Layout from "../components/Layout";
import Container from "../components/utility/Container";
import { Form, Formik } from "formik";
import Grid from "@mui/material/Grid";

// import { ChevronLeft, ChevronRight } from "react-feather";
// import {
//   Carousel
// } from "@material-tailwind/react";
// import { motion } from "framer-motion";
// import AnimVariants from "../data/variants";
import * as Yup from "yup";
// import AnimVariants from "../data/variants";
// import Tooltip from '@mui/material/Tooltip';
import LoadingPage from "../components/LoadingPage";
import FAQPage from "./FAQSection";
import InputField from "../components/inputs/InputField";
//import TextArea from "../components/inputs/TextArea";
//import TextField from '@mui/material/TextField';
import Slider from "./slider";
//import Onboarding from "../components/Onboarding";
//import img1 from "../utils/h1.jpeg";
// import img2 from "../utils/h2.jpeg";
// import img3 from "../utils/4145.jpg";
// import img4 from "../utils/h4.jpeg";
// import img5 from "../utils/h5.jpeg";
// import img6 from "../utils/714.jpeg";
// import data from '../data/data.json';

import icon1 from "../utils/no-fee 1.png";
import icon2 from "../utils/iCON (2).jpg";
import icon3 from "../utils/Icon.jpg";
import icon4 from "../utils/Vector.png";

import Hero from "../utils/home.png";
import Angle from "../utils/Rectangle.png";
// import { testmomies } from "../data";
import step1 from "../utils/step1.png";
import step2 from "../utils/step2.png";
import step3 from "../utils/step3.png";
// import Onboarding from "../components/Onboarding";
import Testimonies from "../pages/Testimonies";
import axios from 'axios'
import { FaMailBulk, FaPhone, FaLocationArrow } from "react-icons/fa";
// import { CustomAxiosError } from "../ts/interfaces/main";
import { errorToast, successToast } from "../app/toasts";
import { Link, useNavigate } from "react-router-dom";
// import http from "../utils/http";

// import { Popup } from 'semantic-ui-react'
export const textAnimate = {
  offscreen: { y: 100, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", bounce: 0.4, duration: 0.5 },
  },
};
const SignUpSchema = Yup.object().shape({
  fullname: Yup.string().required().min(4),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  zipcode: Yup.string().required(),
  address: Yup.string().required(),


});
interface SignUpValues {
  fullname: string;
  phone: string;
  email: string;
  zipcode: string;
  address: string;
}


const messageSchema = Yup.object().shape({
  Name: Yup.string().required().min(4),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  company: Yup.string().required(),
  message: Yup.string().required(),


});
interface messageValues {
  Name: string;
  phone: string;
  email: string;
  company: string;
  message: string;
}





const Features = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  // const [er, setErrors] = useState([]);
  // const slides = [
  //   "https://i.ibb.co/ncrXc2V/1.png",
  //   "https://i.ibb.co/B3s7v4h/2.png",
  //   "https://i.ibb.co/XXR8kzF/3.png",
  //   "https://i.ibb.co/yg7BSdM/4.png",
  // ];
  //   const longText = `
  // Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
  // Praesent non nunc mollis, fermentum neque at, semper arcu.
  // Nullam eget est sed sem iaculis gravida eget vitae justo.
  // `;



  const background = Hero;
  const handleSignUp = (
    val: SignUpValues,
    // setErrors: (errors: FormikErrors<SignUpValues>) => void
  ) => {
    setLoading(true);

    setTimeout(() => {
      // localStorage.setItem('fullname', val.fullname);
      // localStorage.setItem('email', val.email);
      // localStorage.setItem('phone', val.phone);
      // localStorage.setItem('zipcode', val.zipcode);
      // localStorage.setItem('address', val.address);

      // axios("http://localhost:8070/api/v1/amazingproperties/property/create", {
      //   method: "POST",
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   data: JSON.stringify(val),
      // })
      //   .then((response: any) => {
      //     const message = response.data.message;
      //     console.log("hey", message)
      //     if (message == "Fill out all required fields") {
      //       errorToast("Please fill out all required fields");
      //     }

      //     else {
      //       console.log("))))", response.data.user)

      //       successToast("Successful : Thank you for Choosing Amazing Properties");
      //     }
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
      //const data = { val };
      setLoading(false);
      navigate('/sell', { state: val })

      // window.location.href = "";
    }, 3000);
  }

  const handleMessage = (
    val: messageValues,
    // setErrors: (errors: FormikErrors<SignUpValues>) => void
  ) => {
    setLoading(true);

    setTimeout(() => {

      axios("https://amazingproperties-server.onrender.com/api/v1/amazingproperties/contact/create", {
        method: "POST",// https://amazingproperties-server.onrender.com
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(val),
      })
        .then((response: any) => {
          const message = response.data.message;
          console.log("hey", message)
          if (message == "Fill out all required fields") {
            errorToast("Please fill out all required fields");
          }

          else {
            console.log("))))", response)
            successToast("Successful : Thank you for Contacting Amazing Properties");
            window.location.href = ''
          }
        })
        .finally(() => {
          setLoading(false);
        });

    }, 3000);
  }

  // const users = [
  //   {
  //     name: 'Elliot Fu',
  //     bio: 'Elliot has been a member since July 2012',
  //     avatar: '/images/avatar/small/elliot.jpg',
  //   },
  //   {
  //     name: 'Stevie Feliciano',
  //     bio: 'Stevie has been a member since August 2013',
  //     avatar: '/images/avatar/small/stevie.jpg',
  //   },
  //   {
  //     name: 'Matt',
  //     bio: 'Matt has been a member since July 2014',
  //     avatar: '/images/avatar/small/matt.jpg',
  //   },
  // ]
  return (
    <Layout layout="headerAndFooter">
      {/* Hero */}

      <div className="w-full" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: '900px' }}>

        <Container className="flex flex-col lg:pt-[60px] relative justify-between items-center lg:flex-row" >
          {/* <Grid container spacing={2}>
         
            <Grid item xs={6}> */}

          <div className="flex-1 py-10 space-y-4 pt-[60px] md:py-[100px] lg:py-[40px] -mt-10">
            <h1 style={{ color: '#ffff' }} className="leading-tight text-primary leading-11 font-bold text-[40px] md:text-[40px] md:leading-[40px] md:text-left">
              Need To Sell Your House
            </h1>
            <h1 style={{ color: '#ffff' }} className="leading-tight  leading-11 font-bold text-[40px] md:text-[40px] md:leading-[40px] md:text-left">
              Fast in Columbus Ohio?.
            </h1>
            <p className="md:text-left mt-4 leading-[29px] mb-5 font-normal text-base md:text-2xl text-[#fff]">
              We buy houses in Columbus Ohio in any condition or location.
            </p>


          </div>
          {/* </Grid>
            <Grid item xs={true}> */}
          <div className="w-full flex justify-center items-start lg:self-start lg:justify-self-end relative lg:-right-[10px] flex-1" >
            {/* bg-gradient-to-br from-[#ffffff] to-[#ffffff] */}


            <div className="w-full max-w-[500px] z-10 my-8 lg:scale-105 lg:ml-auto lg:my-0 ">


              <div className="  " >

                {/* md:ml-20 md:mr-20 ml-3 mr-3 */}
                <div className="flex flex-col lg:flex-row pl-6 pr-6  justify-between shadow-lg rounded-xl md:p-16 p-4 pt-5 bg-[#ffff]">


                  <div className="flex-1 lg:flex items-center justify-center">
                    <Container className="flex items-center justify-center">
                      <div className="flex-1 space-y-3">
                        <h1 className="my-0 font-bold items-center text-lg lg:text-3xl" style={{ color: '#00B776', textAlign: 'center' }}>
                          Sell your property fast.
                        </h1>
                        <Formik
                          validationSchema={SignUpSchema}
                          initialValues={{
                            fullname: "",
                            phone: "",
                            email: "",
                            zipcode: "",
                            address: "",
                          }}
                          // onSubmit={(e) => {
                          //   handleSignUp(e);
                          // }}

                          onSubmit={(e) => handleSignUp(e)}
                        >
                          {() => (
                            <Form className="space-y-3 w-full lg:w-[400px] mx-auto">
                              <InputField
                                name="fullname"
                                // label="Username"
                                placeholder="full Name"

                              />

                              <InputField
                                name="email"
                                type={"email"}
                                // label="Email Address"

                                placeholder="yourname@gmail.com"
                              />
                              <InputField
                                name="phone"
                                type={"text"}
                                // label="Name"

                                placeholder="Phone Number"
                              />
                              <InputField
                                name="address"
                                type={"text"}
                                // label="Name"

                                placeholder="Property Address"
                              />
                              <InputField
                                name="zipcode"
                                type={"text"}

                                // label="Name"
                                placeholder="Zip Code"
                              />
                              <div className="flex space-y-2 flex-col">
                                {/* <p className={classNames('text-[#CCCCCC] text-xs text-left')}>Password must contain a minimum of 8 characters</p> */}
                                {/* <p className={classNames('text-[#CCCCCC] text-xs text-left')}>Password must contain at least one symbol e.g. @, !</p> */}
                              </div>

                              {loading ? (
                                <LoadingPage />
                              ) : (
                                <button
                                  type="submit"
                                  className="py-2 bg-primary text-white w-full font-bold border-[1px] border-[#00B776] rounded-lg"
                                >
                                  Get an Offer
                                </button>
                              )}

                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Container>
                  </div>


                </div>
              </div>
            </div>

          </div>
          {/* </Grid>
        */}


          {/* Rememner to display component(gradient) and set body overflow to hidden */}
          <div
            className="absolute w-[800px] h-[800px] hidden rounded-[301px] -bottom-20 -right-[300px]"
            style={{
              background:
                "radial-gradient(44.85% 44.85% at 50% 50%, rgba(255, 202, 102, 0.4) 18.46%, rgba(255, 175, 0, 0) 100%)",
            }}
          />
          {/* </Grid> */}
        </Container>

      </div>

      <section className='my-[70px] bg-[#FAFAFA]' >
        <Container>
          <h4 className="text-center text-[18px] md:text-[28px] mb-8 text-primary font-bold capitalize">
            HOW DO I SELL MY HOUSE FAST IN COLUMBUS OHIO?
          </h4>
          <h1 className="text-center text-[18px] md:text-[28px] mb-14 text-primary font-bold capitalize">
            Get started as easy as 1, 2, 3
          </h1>


          <div className="flex mt-5 flex-col lg:flex-row items-center justify-center mx-5">
            <div className="space-y-3 mt-4 flex flex-col items-center justify-center py-4">
              <div className='flex items-center justify-center '>
                <img

                  src={step1}
                  alt="amazing-property"
                  style={{ height: '220px', width: '250px', marginTop: '-70px' }}
                /></div>

              <h1 className='font-semibold text-[#00B776]  text-center'>Submit Your Information</h1>
              <p className='w-4/4 lg:w-[100%] text-sm md:text-base text-[#3A3A3A] mt-3 text-center'>As soon as we receive your request, our team will review your property information.</p>
            </div>
            <div className="space-y-3 flex flex-col items-center justify-center py-4">
              <div className='flex items-center justify-center '>
                <img

                  src={step2}
                  alt="amazing-property"
                  style={{ height: '200px', width: '250px', marginTop: '-30px' }}
                /></div>
              <br />
              <h1 className='font-semibold text-[#00B776] text-center'>Negotiate Price</h1>
              <p className='w-3/4 lg:w-[80%]text-sm md:text-base text-[#3A3A3A] mt-9 text-center ' >We present you with our fair all cash offer.
                Free and no obligation .  Our offers are always made as is based on the local comps in the area .  We use our own cash,
                no banks necessary.</p>
            </div>
            <div className="space-y-3 flex flex-col items-center justify-center py-4">
              <div className='flex items-center justify-center '>
                <img

                  src={step3}
                  alt="amazing-property"
                  style={{ height: '220px', width: '250px', marginTop: '-50px' }}
                />  </div>

              <h1 className='font-semibold text-[#00B776]  text-center'>Choose Your Closing Date</h1>
              <p className='w-3/4 lg:w-[90%] text-sm md:text-base text-[#3A3A3A] mt-3 text-center'>Get your cash in 7 days, no need to wait
                months. It s up to you if you want to close
                quickly or slowly. Super easy, 100% hassle
                free!</p>
            </div>
            {/* 
                        Find icon for 
                        transparent
                        safe
                        reliable
                        */}
          </div>
        </Container>
      </section>

      <section className='w-full py-[60px] bg-no-repeat bg-cover' style={{ marginTop: '10px' }}>
        <Container>
          <div className="flex flex-col lg:flex-row-reverse items-center justify-center space-y-3">
            <div className='lg:flex-[0.2] flex items-center justify-center'>
              {/* <img src={giftCards} className='' alt="we also trade gift cards" /> */}
              <Link
                to="/sell"
                className="py-2  bg-primary border-[1px] rounded-lg text-white  font-bold text-center" style={{ width: '200px', marginTop: '80px' }}
              >
                Get an Offer
              </Link>

            </div>
            <div className='lg:flex-[0.8]'>
              <h1 className='font-bold text-[20px] md:text-[48px] text-left text-primary'>Discover Properties Acquired by us</h1>

              <p className='text-base md:leading-[34px] md:text-xl text-center mt-5 lg:text-left'> Enjoy a fascinating view of some of our recently closed

                deals. <br /> Don t waste time; we buy houses fast!</p>
            </div>
          </div>
          <br />
          <div className="max-w-lg md:max-w-[1980px] "  >
            {/* <div
          
          className="carousel-container relative flex gap-1 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
        >
          <Onboarding autoSlide={true}>
            {data.resources.map((resource,index) => (
               <div
               key={index}
               className="carousel-item text-center relative w-80 h-80 snap-start"
             >
         
               <a
                 href={resource.link}
                 className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
                 style={{ backgroundImage: `url(${resource.imageUrl || ''})` }}
               >
                 
                 <img
                   src={resource.imageUrl || ''}
                   alt={resource.title}
                   //className="relative w-[100%] md:w-[100%] "
                   className="w-full aspect-square hidden"
                 />
                 
               </a>
               <a
                 href={resource.link}
                 style={{height:'100px'}}
                 className="w-full aspect-square block absolute bottom-0 left-0 transition-opacity duration-300 opacity-0 hover:opacity-100 bg-blue-900/45 z-10"
                // className="h-full w-full aspect-square block absolute top-0 left-0 transition-opacity duration-300  bg-blue-800/75 z-10"
               >
                
                 <h3 className="text-white py-1 px-3 mx-auto text-xl">
                 Property Location
               </h3>
               <h3 className="text-white py-1 px-3 mx-auto text-xl">
                 {resource.title}
               </h3>
               </a>
             </div>
            ))}
          </Onboarding>
</div> */}
            <Slider />


          </div>
        </Container>
      </section>


      <section className='w-full py-[60px] bg-no-repeat bg-cover'>
        <Container>
          <h4 className="text-center text-[18px] md:text-[28px] mb-10 text-primary  capitalize">
            WHY CHOOSE US
          </h4>
          <h4 className="text-center text-[18px] md:text-[28px] mb-10 text-primary font-bold  capitalize">
            We are a strategic choice
          </h4>

          <Grid container spacing={2}>
            <div className="item-center justify-center" style={{ margin: 'auto' }}>
              <Link
                to="/sell"
                className="py-4 px-10 bg-primary border-[1px] rounded-lg text-white  font-bold" style={{ width: '200px', margin: 'auto' }}
              >
                Get an Offer
              </Link>
            </div>
            {/* <Grid item xs={12}  className="item-center justify-center" style={{margin:'auto'}} > 
               <a
              href="/sell"
              className="py-4 px-10 bg-primary border-[1px] rounded-lg text-white  font-bold" style={{ width: '200px', margin: 'auto' }}
            >
              Get an Offer
            </a>
            </Grid> */}
          </Grid>

          <br />

          <div className="flex flex-col lg:flex-row-reverse items-center justify-center space-y-3" style={{ marginTop: '45px' }}>

            <Grid container spacing={2}>
              <Grid item xs={6}   > <div className="col-start-2">
                <div className="space-y-1  flex flex-col items-center justify-center ">
                  <img

                    src={icon3}
                    alt="amazing"
                  />

                  <h1 className='font-semibold text-[#00B776]  text-center'>Sell Your House Quickly</h1>
                  <p className='w-3/4 lg:w-auto text-sm md:text-base text-[#3A3A3A] mt-3 text-center' style={{marginRight: '50px'}}>We will make an offer within 24
                    hours of seeing your home.
                    When we make an offer to buy
                    your house, we can close on
                    your schedule, in as little as 5
                    days.</p>
                </div>
              </div></Grid>
              <Grid item xs={6}   >
                <div className="col-start-6  " >
                  <div className="space-y-1 flex flex-col items-center justify-center  ">
                    {/* <div className='flex items-center justify-center text-white bg-primary font-bold w-5 h-5 p-5 rounded-full'>   </div>    */}
                    <img

                      src={icon1}
                      alt="amazing"
                    />

                    <h1 className='font-semibold text-[#00B776] text-center'>No Agents or Fees</h1>
                    <p className='w-3/4 lg:w-auto text-sm md:text-base text-[#3A3A3A] mt-3 text-center'>The traditional home selling
                      process is awful. Agents parade
                      dozens of buyers through your
                      home and typically charge a 5%
                      to 6% commission, adding up to
                      thousands of dollars that you’ll
                      never see.</p>
                  </div>

                </div></Grid>
              <Grid item xs={6}   >
                <div className="col-start-2">
                  <div className="space-y-1 flex flex-col items-center justify-center ">
                    <img

                      src={icon4}
                      alt="amazing"
                    />

                    <h1 className='font-semibold text-[#00B776] text-center'>As-Is Condition</h1>
                    <p className='w-3/4 lg:w-auto text-sm md:text-base text-[#3A3A3A] mt-3 text-center' style={{marginRight: '50px'}}>We want to purchase your home
                      regardless of it’s condition and
                      you’ll never have to make any
                      repairs. It doesn’t matter if your
                      home needs major remodeling or
                      even a complete rebuild.</p>
                  </div></div></Grid>
              <Grid item xs={6}   >
                <div className="col-start-6 " >
                  <div className="space-y-1  flex flex-col items-center justify-center ">
                    <img

                      src={icon2}
                      alt="amazing"
                    />

                    <h1 className='font-semibold text-[#00B776] text-center'>24/7 Support</h1>
                    <p className='w-3/4 lg:w-auto  text-sm md:text-base text-[#3A3A3A] mt-3 text-center'>Our response rate is fast and very reliable. You can count on us to be at your service always.</p>
                  </div>

                </div></Grid>
            </Grid>



          </div>

        </Container>
      </section>

      <section className='w-full py-[60px] bg-no-repeat bg-cover'>
        <Container>
          <h1 className="text-center text-[18px] md:text-[48px] mb-3 text-primary  capitalize">
            Testimonials
          </h1>
          <h1 className="text-center text-[38px] md:text-[48px] mb-3 text-primary font-bold capitalize">
            What Sellers are saying
          </h1>


          <div className=" max-w-lg md:max-w-[1980px] " >
            <Testimonies />

          </div>
        </Container>
      </section>
      <h1 className="text-center text-[18px] md:text-[48px] mb-3 text-primary  capitalize" id="faq">
        Frequently Asked Questions
      </h1 >
      <FAQPage />



      {/* <motion.div
        variants={AnimVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="relative"
      > */}
      <section className='w-full py-[10px] bg-no-repeat bg-cover'>
        <div className="w-full flex flex-col lg:flex-row-reverse items-center justify-center space-y-3">
          <div className='lg:flex-[0.6] flex items-center justify-center w-[100%]' style={{ height: '490px' }}>
            <img
              className=" w-[100%] md:w-[100%] "
              src={Angle}
              alt="amazing-property"
              style={{ height: '492px', marginTop: '10px' }}

            />

          </div>
          <div className='lg:flex-[0.6] bg-primary' style={{ height: '492px' }}>


            <div className=" h-screen  items-center justify-center ">
              <Container className="flex items-center justify-center ">
                <div className="flex-1 space-y-10">

                  <Grid container spacing={4}>
                    <Grid item xs={12} >
                      <br />
                      <h1 className='font-bold text-[35px] text-center mx-auto lg:mx-0  lg:text-left lg:w-[70%]  text-white md:leading-[50px]'>Get A fair Cash Offer</h1>

                    </Grid>
                    <Grid item xs={12} >
                      <p className='text-base md:leading-[34px] md:text-xl text-center  lg:text-left text-[#fff] ' >
                        Selling your home is a significant decision, and getting
                        a fair cash offer is often the foremost concern for
                        home owners. Getting a fair cash offer for your home
                        provides an array of benefits, allowing you to sell
                        quickly, avoid extensive preparations, and proceed
                        with confidence. Embrace the ease and certainty of a
                        fair cash offer to unlock the full potential of selling
                        your home hassle free. </p>
                    </Grid>
                    <Grid item xs={12} >
                      <div >
                        <Link
                          to="/sell"
                          className="py-2 px-10 bg-white rounded-3x1 text-primary  font-bold border-[1px] rounded-lg " style={{ width: '100px', }}
                        >
                          Get an Offer
                        </Link>

                      </div>
                    </Grid>
                  </Grid>






                </div>
              </Container>
            </div>


          </div>
        </div>
      </section>



      {/* 
      <Grid container spacing={0}>
        <Grid item xs={6} className="bg-[#00B776]" style={{ height: '500px', marginLeft: 'auto' }}>
          <div className=" h-screen  items-center justify-center ">
            <Container className="flex items-center justify-center ">
              <div className="flex-1 space-y-10">

              <Grid container spacing={6}>
        <Grid item xs={12} >
        <h1 className='font-bold text-[35px] text-center mx-auto lg:mx-0  lg:text-left lg:w-[70%]  text-white md:leading-[50px]'>Get A fair Cash Offer</h1>

        </Grid>
        <Grid item xs={12} >
        <p className='text-base md:leading-[34px] md:text-xl text-center  lg:text-left text-[#fff] ' >
                  Selling your home is a significant decision, and getting
                  a fair cash offer is often the foremost concern for
                  home owners. Getting a fair cash offer for your home
                  provides an array of benefits, allowing you to sell
                  quickly, avoid extensive preparations, and proceed
                  with confidence. Embrace the ease and certainty of a
                  fair cash offer to unlock the full potential of selling
                  your home hassle free. </p>
        </Grid>
        <Grid item xs={12} >
        <div >
                  <a
                    href="/sell"
                    className="py-2 px-10 bg-white rounded-3x1 text-primary  font-bold border-[1px] rounded-lg " style={{ width: '100px', }}
                  >
                    Get an Offer
                  </a>

                </div>
        </Grid>
        </Grid>
                
             

               


              </div>
            </Container>
          </div>
        </Grid>
        <Grid item xs={6} className=" w-[100%]  "  >

          <img
            className=" w-[100%] md:w-[100%] "
            src={Angle}
            alt="amazing-property"
            style={{ height: '500px' }}

          />

        </Grid>
      </Grid> */}



      <section className='w-full py-[20px] bg-no-repeat bg-cover ' id="contactus">
        <Container>
          <div className="flex flex-col lg:flex-row-reverse items-center justify-center space-y-3">
            <div className='lg:flex-[0.5] ' style={{ marginLeft: 'auto' }}>
              {/* <img src={giftCards} className='' alt="we also trade gift cards" /> */}

              <Formik
                validationSchema={messageSchema}
                initialValues={{
                  Name: "",
                  phone: "",
                  email: "",
                  company: "",
                  message: "",
                }}
                onSubmit={(e,) => {
                  handleMessage(e);
                }}
              >
                {() => (
                  <Form className="space-y-0 w-full lg:w-[100%] mx-auto  " style={{ marginTop: '130px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} style={{ margin: 'auto' }}>
                        <InputField
                          name="Name"
                          type={"text"}
                          label="Name"
                          placeholder="john Doe"
                        />
                      </Grid>
                      <Grid item xs={6} style={{ margin: 'auto' }}>
                        <InputField
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="yourname@gmail.com"
                        />
                      </Grid>
                      <Grid item xs={6} style={{ margin: 'auto' }}>
                        <InputField
                          name="company"
                          type={"text"}
                          label="Company"
                          placeholder="Facebook"
                        />
                      </Grid>

                      <Grid item xs={6} style={{ margin: 'auto' }}>
                        <InputField
                          name="phone"
                          type="tel"
                          label="Phone"
                          placeholder="(123) 456 789"
                        />
                      </Grid>

                    </Grid>
                    <br />

                    <div className="flex space-y-1 flex-col" style={{ textAlign: 'center' }}>
                      {/* <p className={classNames('text-[#CCCCCC] text-xs text-left')}>Password must contain a minimum of 8 characters</p> */}
                      {/* <p className={classNames('text-[#CCCCCC] text-xs text-left')}>Password must contain at least one symbol e.g. @, !</p> */}
                      <InputField
                        name="message"
                        type={"text"}

                        placeholder="Enter message"
                        style={{ height: '100px' }}
                      />


                    </div>
                    <br />
                    <Grid container >
                      <Grid item xs={5} md={6} lg={5} className="items-center justify-center" style={{ margin: 'auto' }}>
                        {loading ? (
                          <LoadingPage />
                        ) : (
                          <button
                            type="submit"
                            className="py-3 bg-primary border-[1px] rounded-lg text-white  font-bold" style={{ width: '250px', }}
                          >
                            Send message
                          </button>
                        )}
                      </Grid>

                    </Grid>




                  </Form>
                )}
              </Formik>


              {/* <button
                type="submit"
                className="py-3 bg-primary rounded-2xl text-white  font-bold" style={{ width: '250px' }}
              >
                Send message
              </button> */}

            </div>
            <div className='lg:flex-[0.5] space-y-10' >
              <h1 className='font-bold text-[20px] md:text-[48px] text-left text-primary'>Get in touch today</h1>
              <p className='text-base md:leading-[34px] md:text-xl text-left mt-5 lg:text-left text-dark'> Do you have some questions youd like to ask us?

                Great! We love talking with home owners to see how we can
                help you reach your goals.<br />

              </p>

              <h4 className='mx-auto lg:mx-0 text-[20px]  lg:text-left lg:w-[70%] text-center mb-1 text-gray-700  ' style={{ display: 'flex' }}><span><FaMailBulk color="#00B776" size={28} /></span><span style={{ marginLeft: '20px' }}>contact@amazingpropertiesusa.com</span>
              </h4>

              <h4 className='mx-auto lg:mx-0 text-[20px]  lg:text-left lg:w-[70%] text-center mb-1 text-gray-700  capitalize' style={{ display: 'flex' }}><span><FaPhone color="#00B776" size={28} /></span><span style={{ marginLeft: '20px' }}>(614) 647 7088</span></h4>

              <h4 className='mx-auto lg:mx-0 text-[20px]  lg:text-left lg:w-[70%] text-center mb-1 text-gray-700  capitalize' style={{ display: 'flex' }}><span><FaLocationArrow color="#00B776" size={28} /></span><span style={{ marginLeft: '20px' }}>5001 1st Ave SE suite 105-221
                Cedar Rapids IA 52402</span></h4>

              {/* <p className='text-base md:leading-[34px] md:text-xl text-center mt-5 lg:text-left py-5 text-[#fff]'>Lorem ipsum dolor sit amet consecte turddddd <br/> adipiscing elit semper dalar consectur eledm <br/> tempus hac.</p> */}
            </div>
          </div>
        </Container>
      </section>


    </Layout>
  );
};

export default Features;




