import React, { Fragment, useCallback, useState } from "react";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../app/hooks";
import { errorToast } from "../../app/toasts";
import { TradeSlice } from "../../features/contacts/slice";
import { Trade } from "../../state/types";
import { formatDate } from "../../utils";
import http from "../../utils/http";
import LoadingPage from "../LoadingPage";
import useModal from "../Modal/useModal";
import { getTradeStatusColor } from "../utility";

type Props = {
  trade?: Trade;
};

export default function ViewTransactionModal({ trade }: Props) {
  const [fetching, setFetching] = useState(false);

  const dispatch = useAppDispatch();
  const [, onClose] = useModal(null);

  const respondToTrade = useCallback(
    (type: "approve" | "reject") => {
      if (!trade) return;
      setFetching(true);
      http
        .get(
          `/admin/trade/${type}/trade_from_admin?from_admin=true&trade_id=${trade.id}`
        )
        .then((response) => {
          const trade = response as unknown as Trade;
          if (trade.status === "approved") {
            Swal.fire(
              "Trade Approved",
              "You can view transaction details in transaction page",
              "success"
            ).then((res) => {
              if (res.isConfirmed || res.dismiss) {
                dispatch(TradeSlice.actions.addTrade(trade));
              }
            });
          } else if (trade.status === "declined") {
            Swal.fire(
              "Trade Declined!",
              "You can view transaction details in transaction page or your mail",
              "error"
            ).then((res) => {
              if (res.isConfirmed || res.dismiss) {
                dispatch(TradeSlice.actions.addTrade(trade));
              }
            });
          }
        })
        .catch((err: any) => {
          console.error(err);
          errorToast("An error occured while responding to trade");
        })
        .finally(() => {
          setFetching(false);
          onClose();
        });
    },
    [dispatch, trade, onClose]
  );

  const approveTrade = () => {
    respondToTrade("approve");
  };
  const rejectTrade = () => {
    respondToTrade("reject");
  };

  const handleTradeAfterPayment = useCallback(
    (type: "success" | "failed") => {
      if (!trade) return;
      setFetching(true);
      http
        .post("/trade/complete", {
          trade_id: trade.id,
          tradeReference: trade.tradeReference,
          status: type,
        })
        .then((response) => {
          const trade = response as unknown as Trade;
          if (trade.released) {
            Swal.fire(
              "Trade Completed",
              "You have released funds to the user",
              "success"
            ).then((res) => {
              if (res.isConfirmed || res.dismiss) {
                dispatch(TradeSlice.actions.addTrade(trade));
              }
            });
          } else if (trade.released === false) {
            Swal.fire(
              "Trade Completed",
              "This trade is closed and no further action is required from you. You did not sent any crypto to the user",
              "error"
            ).then((res) => {
              if (res.isConfirmed || res.dismiss) {
                dispatch(TradeSlice.actions.addTrade(trade));
              }
            });
          }
        })
        .catch((err: any) => {
          console.error(err);
          errorToast("An error occured while responding to trade");
        })
        .finally(() => {
          setFetching(false);
          onClose();
        });
    },
    [trade, dispatch, onClose]
  );

  if (!trade) return <div></div>;

  const renderApproveCard = () => (
    <div className="w-full overflow-hidden rounded-lg bg-white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto mt-8 h-16 w-16 text-green-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
      <h1 className="mt-2 text-center text-2xl font-bold text-gray-500">
        Approve pending trade
      </h1>
      <p className="my-4 text-center text-sm text-gray-500">
        Please confirm you are ready to attend to this request.
      </p>
      <div className="space-x-2 py-4 text-center flex items-center px-2 justify-between">
        {fetching ? (
          <LoadingPage />
        ) : (
          <Fragment>
            <button
              onClick={rejectTrade}
              disabled={fetching}
              className="inline-block rounded-md bg-red-500 px-6 py-2 font-semibold text-red-100 shadow-md
                duration-75 hover:bg-red-400 w-full cursor-pointer disabled:opacity-40"
            >
              Decline
            </button>
            <button
              onClick={approveTrade}
              disabled={fetching}
              className="inline-block rounded-md bg-green-500 px-6 py-2 font-semibold text-green-100 shadow-md
                duration-75 hover:bg-green-400 w-full cursor-pointer disabled:opacity-40"
            >
              Approve
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );

  const renderCompleteCard = () => (
    <div className="w-full overflow-hidden rounded-lg bg-white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="mx-auto mt-8 h-16 w-16 text-green-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
      <h1 className="mt-2 text-center text-2xl font-bold text-gray-500">
        Send funds to user.
      </h1>
      <p className="my-4 text-center text-sm text-gray-500">
        Please confirm you have succesfully sent funds to this user to close
        this trade
      </p>
      <div className="space-x-2 py-4 text-center flex items-center px-2 justify-between">
        {fetching ? (
          <LoadingPage />
        ) : (
          <Fragment>
            <button
              disabled={fetching}
              onClick={() => handleTradeAfterPayment("failed")}
              className="inline-block rounded-md bg-red-500 px-6 py-2 font-semibold text-red-100 shadow-md
                duration-75 hover:bg-red-400 w-full cursor-pointer"
            >
              Cancel
            </button>
            <button
              disabled={fetching}
              onClick={() => handleTradeAfterPayment("success")}
              className="inline-block rounded-md bg-green-500 px-6 py-2 font-semibold text-green-100 shadow-md
                duration-75 hover:bg-green-400 w-full cursor-pointer"
            >
              Sent
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );

  const renderClosedCard = () => (
    <div className="text-3xl font-light text-red-500 flex justify-center items-center">
      This trade was declined by an admin and is not open to further
      interactions.
    </div>
  );

  const renderUserClosedCard = () => (
    <div className="text-3xl font-light text-red-500 flex justify-center items-center">
      This trade was closed by user and is not open to further interactions.
    </div>
  );

  const renderApprovedCard = () => (
    <div className="text-3xl font-light text-gray-500 flex justify-center items-center">
      You have approved this trade. Waiting for user to pay...
    </div>
  );

  const renderCompletedCard = () => (
    <div className="text-2xl font-light text-gray-500 flex justify-center items-center">
      Completed trade {formatDate(trade.updated_at)} with user
    </div>
  );

  return (
    <div
      className="flex flex-col relative md:flex-row rounded-lg border border-gray-200/80 bg-white p-6 max-w-screen-lg
        mx-auto"
    >
      <div className="relative">
        <img
          className="w-20 h-30 rounded-md object-cover"
          src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
          alt="User"
        />
        <div
          className="absolute md:-right-3 bottom-5 h-5 w-5 sm:top-2 rounded-full border-4 border-white bg-green-400 sm:invisible md:visible"
          title="User is online"
        ></div>
      </div>

      <div className="flex flex-col md:px-6 w-full">
        <div className="flex flex-col">
          <p>
            Trade Reference <b>{trade.tradeReference}</b>
          </p>
        </div>

        <div className="my-2 flex flex-row space-x-2">
          <div className="flex flex-row">
            <svg
              className="mr-2 h-4 w-4 fill-gray-500/80"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
            </svg>

            <div className="text-xs text-gray-400">
              {formatDate(trade.updated_at)}
            </div>
          </div>
        </div>

        <div className="my-5 flex flex-col md:flex-row md:justify-between md:space-x-10 space-y-5 w-full">
          <div className="bg-white shadow-sm p-6 rounded-2xl border-2 w-full max-w-xs border-gray-50">
            <div className="flex flex-col">
              <div>
                <h2 className="font-bold text-gray-600">
                  {trade.crypto.name} {trade.crypto.symbol}
                </h2>
              </div>
              <div className="my-3">
                <div className="flex flex-row space-x-3 items-center">
                  <div id="icon">
                    <span>
                      {/* <img
                        className="w-16"
                        alt=""
                        src={tokenImageDownloader(trade.crypto.logo)}
                      /> */}
                    </span>
                  </div>
                  <div id="temp">
                    <h4 className="text-2xl">N{trade.crypto.buy_rate}</h4>
                    <p className="text-xs text-gray-500">Buy Rate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {trade.status === "pending" && renderApproveCard()}
          {trade.status === "approved" &&
            (trade.paid ? renderCompleteCard() : renderApprovedCard())}
          {trade.status === "completed" && renderCompletedCard()}
          {trade.status === "declined" && renderClosedCard()}
          {trade.status === "closed" && renderUserClosedCard()}
        </div>

        <div className="mt-10 flex flex-row items-center space-x-8">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-center">
              <svg
                className="mr-3 fill-green-600/95"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M2.5 19.6L3.8 20.2V11.2L1.4 17C1 18.1 1.5 19.2 2.5 19.6M15.2 4.8L20.2 16.8L12.9 19.8L7.9 7.9V7.8L15.2 4.8M15.3 2.8C15 2.8 14.8 2.8 14.5 2.9L7.1 6C6.4 6.3 5.9 7 5.9 7.8C5.9 8 5.9 8.3 6 8.6L11 20.5C11.3 21.3 12 21.7 12.8 21.7C13.1 21.7 13.3 21.7 13.6 21.6L21 18.5C22 18.1 22.5 16.9 22.1 15.9L17.1 4C16.8 3.2 16 2.8 15.3 2.8M10.5 9.9C9.9 9.9 9.5 9.5 9.5 8.9S9.9 7.9 10.5 7.9C11.1 7.9 11.5 8.4 11.5 8.9S11.1 9.9 10.5 9.9M5.9 19.8C5.9 20.9 6.8 21.8 7.9 21.8H9.3L5.9 13.5V19.8Z" />
              </svg>

              <span className="font-bold text-green-600">
                {trade.currency.symbol}
              </span>
            </div>

            <div className="mt-1 text-xs text-gray-400">Currency</div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-center">
              <svg
                className="mr-3 fill-gray-500/95"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M5.68,19.74C7.16,20.95 9,21.75 11,21.95V19.93C9.54,19.75 8.21,19.17 7.1,18.31M13,19.93V21.95C15,21.75 16.84,20.95 18.32,19.74L16.89,18.31C15.79,19.17 14.46,19.75 13,19.93M18.31,16.9L19.74,18.33C20.95,16.85 21.75,15 21.95,13H19.93C19.75,14.46 19.17,15.79 18.31,16.9M15,12A3,3 0 0,0 12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12M4.07,13H2.05C2.25,15 3.05,16.84 4.26,18.32L5.69,16.89C4.83,15.79 4.25,14.46 4.07,13M5.69,7.1L4.26,5.68C3.05,7.16 2.25,9 2.05,11H4.07C4.25,9.54 4.83,8.21 5.69,7.1M19.93,11H21.95C21.75,9 20.95,7.16 19.74,5.68L18.31,7.1C19.17,8.21 19.75,9.54 19.93,11M18.32,4.26C16.84,3.05 15,2.25 13,2.05V4.07C14.46,4.25 15.79,4.83 16.9,5.69M11,4.07V2.05C9,2.25 7.16,3.05 5.68,4.26L7.1,5.69C8.21,4.83 9.54,4.25 11,4.07Z" />
              </svg>

              <span
                className="font-normal"
                style={{ color: getTradeStatusColor(trade.status) }}
              >
                {trade.status}
              </span>
            </div>

            <div className="mt-1 text-xs text-gray-400">Status</div>
          </div>
        </div>
      </div>
    </div>
  );
}
