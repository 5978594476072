import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "../../components/utility/Container";
import { IoSettingsOutline, IoGridOutline } from "react-icons/io5";
import { AiOutlineLogout } from "react-icons/ai";
import { RiTodoLine } from "react-icons/ri";
import { BsChatDots } from "react-icons/bs";
import classNames from "classnames";
import Logout from "./Logout";
import { errorToast, infoToast, successToast } from "../../app/toasts";
import { Chat } from "../../ts/interfaces/main";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useAppSocket from "../../app/useAppSocket";
import type { TradeOrderPayload } from "../../types";
import { TradeSlice } from "../../features/contacts/slice";
import { GiftCardTradeSlice } from "../../features/giftCard/slice";
import { getContactsInfo } from "../../features/contacts/thunkActions";
import { MdOutlineClose } from "react-icons/md";
import { BiMenu } from "react-icons/bi";
const adminLinks = [
  "/admin/",
  "/admin/transactions",
  "/admin/settings",
  "/admin/logout",
  "/admin/chat",
  "/admin/transactions/giftcard",
];

const audioUrl =
  "https://audio-previews.elements.envatousercontent.com/files/82685078/preview.mp3?response-content-disposition=attachment%3B+filename%3D%22ZURQ2FE-notification.mp3%22";
const audio = new Audio(audioUrl);

function playSound() {
  audio.play();
}
export const onFailure = (error: string) => {
  console.error(error);
};
const SideBar = () => {
  // const [selectedNav, setSelectedNav] = useState<string>('/admin/')
  const [newTrade, setNewTrade] = useState<boolean>(false);
  const [newGiftCardTrade, setNewGiftCardTrade] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<boolean>(false);
  const [active, setActive] = useState(false);
  const [logout, setLogout] = useState<boolean>(false);
  const { data: admin } = useAppSelector((state) => state.user);
  const { data: contact } = useAppSelector((state) => state.contactInfo);
  const dispatch = useAppDispatch();
  const { listen } = useAppSocket();
  const handleNewContact = React.useCallback(
    (id: number) => {
      // check if user id is not present in the list of id's present
      // if above is true, dispatch getAllContacts
      // Bug: Value of contact.contacts is not updating after getting the new contact in the redux store
      const prevIds = contact.contacts?.map((i: any) => i.id);
      if (typeof prevIds !== "undefined") {
        if (!prevIds.includes(id)) {
          dispatch(getContactsInfo({ onFailure }));
          return;
        }
      }
    },
    [contact, dispatch]
  );

  const handleMessagePayload = React.useCallback(
    (newChat: { message: Chat }) => {
      if (admin) {
        if (parseInt(newChat.message.from_id) !== admin.id) {
          playSound();
          setNewMessage(true);
          infoToast("New message");
          handleNewContact(parseInt(newChat.message.from_id));
        }
      }
    },
    [admin, handleNewContact]
  );

  const handlePaymentPayload = useCallback(
    (payload: TradeOrderPayload) => {
      const { trade, giftCardTrade } = payload;
      if (payload.action === "trade_created") {
        dispatch(TradeSlice.actions.addTrade(trade));
        playSound();
        setNewTrade(true);
        infoToast("New Trade Initiated");
      }
      if (payload.action === "trade_closed") {
        dispatch(TradeSlice.actions.addTrade(trade));
        playSound();
        setNewTrade(true);
      }
      if (payload.action === "trade_payment") {
        if (trade.paid) {
          successToast(
            `User payment for ${trade.tradeReference} have been confirmed please procees to release funds.`
          );
        } else {
          errorToast(`User payment for ${trade.tradeReference} failed`);
        }
        dispatch(TradeSlice.actions.addTrade(trade));
      }

      if (payload.action === "giftCard_trade_created") {
        dispatch(GiftCardTradeSlice.actions.addGiftCardTrade(giftCardTrade));
        playSound();
        setNewGiftCardTrade(true);
        infoToast("New Gift Card Trade Initiated");
      }
    },
    [dispatch]
  );
  useEffect(() => {
    const cleanUp = listen(handleMessagePayload, `chat-testing`, ".messaging");
    return cleanUp;
  }, [handleMessagePayload, listen]);

  useEffect(() => {
    const cleanUp = listen(
      handlePaymentPayload,
      `admin-trade`,
      ".MessageReceived"
    );
    return cleanUp;
  }, [handlePaymentPayload, listen]);

  const location = useLocation();
  const getClassName = (e: string) => {
    return classNames(
      "inline-flex items-center gap-3 py-3 pl-3 my-2 w-full rounded",
      {
        "bg-primary text-white": location.pathname === e,
        "text-[#808080]": location.pathname !== e,
      }
    );
  };

  return (
    <Fragment>
      <div className="bg-white w-full p-3 py-4 shadow-lg flex fixed top-0 left-0 z-40 md:hidden items-center justify-between">
        <div>
          {active ? (
            <MdOutlineClose
              className="text-darkGray"
              size={36}
              onClick={() => setActive(false)}
            />
          ) : (
            <BiMenu
              className="text-darkGray"
              size={36}
              onClick={() => setActive(true)}
            />
          )}
        </div>
        <Link
          to="/admin"
          onClick={() => {
            setActive(false);
          }}
          className="flex gap-x-5 items-center justify-center"
        >
          <img
            src="https://www.flaticon.com/free-icon/done_7799536"
            className="w-[25px]"
            alt="Spectrum logo"
          />
          <h1 className="text-primary font-bold text-lg md:text-2xl">
            Spectrum
          </h1>
        </Link>
        <div className=""></div>
      </div>
      <div
        className="hidden md:flex h-[100vh] py-[60px] lg:w-[275px] fixed flex-col items-center justify-between border-r
        border-[#CCCCCC] sm:relative"
      >
        <Link to="/admin" className="flex gap-x-5 items-center justify-center">
          <img
            src="hhttps://www.flaticon.com/free-icon/done_7799536"
            className="w-[25px]"
            alt="Spectrum logo"
          />
          <h1 className="text-primary font-bold text-lg md:text-2xl">
            Spectrum
          </h1>
        </Link>

        <Container>
          <ul>
            <li>
              <Link to={adminLinks[0]} className={getClassName(adminLinks[0])}>
                <IoGridOutline size={20} /> <p>Dashboard</p>
              </Link>
            </li>
            <li className="relative">
              <Link
                onClick={() => {
                  setNewTrade(false);
                }}
                to={adminLinks[1]}
                className={getClassName(adminLinks[1])}
              >
                <RiTodoLine size={20} /> <p>Cryptos</p>
                {newTrade && (
                  <div className="w-2 h-2 rounded-full bg-red-600 absolute left-0 top-6" />
                )}
              </Link>
            </li>
            <li className="relative">
              <Link
                onClick={() => {
                  setNewGiftCardTrade(false);
                }}
                to={adminLinks[5]}
                className={getClassName(adminLinks[5])}
              >
                <RiTodoLine size={20} /> <p>GiftCard</p>
                {newGiftCardTrade && (
                  <div className="w-2 h-2 rounded-full bg-red-600 absolute left-0 top-6" />
                )}
              </Link>
            </li>
            <li className="relative">
              <Link
                onClick={() => {
                  setNewMessage(false);
                }}
                to={adminLinks[4]}
                className={getClassName(adminLinks[4])}
              >
                <BsChatDots size={20} />
                <p>Chat</p>
                {newMessage && (
                  <div className="w-2 h-2 rounded-full bg-red-600 absolute left-0 top-6" />
                )}
              </Link>
            </li>
          </ul>
        </Container>

        <Container>
          <ul>
            <li>
              <Link to={adminLinks[2]} className={getClassName(adminLinks[2])}>
                <IoSettingsOutline size={20} /> <p>Settings</p>
              </Link>
            </li>
            <li>
              <Link
                to={"#"}
                onClick={() => {
                  setLogout(true);
                }}
                className={`${getClassName(adminLinks[3])} !text-[#A65959]`}
              >
                <AiOutlineLogout size={20} className="text-inherit" />
                <p className="text-inherit">Logout</p>
              </Link>
            </li>
          </ul>
        </Container>
      </div>

      <div
        className={classNames(
          "z-30 px-6 pt-32 fixed md:hidden shadow-md rounded top-0 left-0 w-8/12 h-[100vh] py-[60px] overflow-auto bg-white transition-all duration-[0.5s] ease-in",
          { "translate-x-[0%] opacity-100": active },
          { "translate-x-[-100%] opacity-0": !active }
        )}
      >
        <Container>
          <ul>
            <li>
              <Link
                to={adminLinks[0]}
                onClick={() => {
                  setActive(false);
                }}
                className={getClassName(adminLinks[0])}
              >
                <IoGridOutline size={20} /> <p>Dashboard</p>
              </Link>
            </li>
            <li className="relative">
              <Link
                onClick={() => {
                  setNewTrade(false);
                  setActive(false);
                }}
                to={adminLinks[1]}
                className={getClassName(adminLinks[1])}
              >
                <RiTodoLine size={20} /> <p>Cryptos</p>
                {newTrade && (
                  <div className="w-2 h-2 rounded-full bg-red-600 absolute left-0 top-6" />
                )}
              </Link>
            </li>
            <li className="relative">
              <Link
                onClick={() => {
                  setNewGiftCardTrade(false);
                  setActive(false);
                }}
                to={adminLinks[5]}
                className={getClassName(adminLinks[5])}
              >
                <RiTodoLine size={20} /> <p>GiftCard</p>
                {newGiftCardTrade && (
                  <div className="w-2 h-2 rounded-full bg-red-600 absolute left-0 top-6" />
                )}
              </Link>
            </li>
            <li className="relative">
              <Link
                onClick={() => {
                  setNewMessage(false);
                  setActive(false);
                }}
                to={adminLinks[4]}
                className={getClassName(adminLinks[4])}
              >
                <BsChatDots size={20} />
                <p>Chat</p>
                {newMessage && (
                  <div className="w-2 h-2 rounded-full bg-red-600 absolute left-0 top-6" />
                )}
              </Link>
            </li>
          </ul>
        </Container>

        <Container>
          <ul>
            <li>
              <Link
                to={adminLinks[2]}
                onClick={() => {
                  setActive(false);
                }}
                className={getClassName(adminLinks[2])}
              >
                <IoSettingsOutline size={20} /> <p>Settings</p>
              </Link>
            </li>
            <li>
              <Link
                to={"#"}
                onClick={() => {
                  setLogout(true);
                }}
                className={`${getClassName(adminLinks[3])} !text-[#A65959]`}
              >
                <AiOutlineLogout size={20} className="text-inherit" />
                <p className="text-inherit">Logout</p>
              </Link>
            </li>
          </ul>
        </Container>
      </div>

      {logout && <Logout onClose={() => setLogout(false)} />}
    </Fragment>
  );
};

export default SideBar;
