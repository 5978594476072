import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/http";
import { ApiResponse, ReduxAction } from "../../ts/interfaces/redux";
import { addCrpytoTypes } from "../../components/Admin/AddCrypto";

interface NetworkValue {
  id: number;
  name: string;
  short_name: string;
}

export interface CryptoCurrency {
  id: number;
  name: string;
  symbol: string;
  buy_rate: number;
  sell_rate: number;
  image_url: string;
}

export interface AddCryptoReturnValue {
  address?: string;
  name: string;
  symbol: string;
  decimals?: number;
  buy_rate: number;
  sell_rate: number;
  network_id?: number;
}

interface listCryptosResponse extends ApiResponse {
  data: CryptoCurrency[] | [];
}

export interface CurrencyValue {
  id: number;
  name: string;
  symbol: string;
  base_rate: number;
  created_at: string;
  updated_at: string;
}
export interface GiftCardValue {
  id: number;
  name: string;
  image_url: string;
}
// interface CurrencyResponseValue extends ApiResponse {
//   data: CurrencyValue[] | [];
// }
export interface NetworkReturnValue {
  id: number;
  name: string;
  short_name: string;
}
interface NetworkResponseValue extends ApiResponse {
  data: NetworkValue[] | [];
}
interface GiftcardResponseValue extends ApiResponse {
  data: GiftCardValue[];
}

export const showAllCrpyto = createAsyncThunk(
  "crypto/all",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: listCryptosResponse = await axios.get("/cryptos");

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

interface AddCryptoArgs extends ReduxAction {
  values: addCrpytoTypes & { logo: File };
}
export const addCrpyto = createAsyncThunk(
  "crypto/add",
  async (
    { values, onSuccess, onFailure }: AddCryptoArgs,
    { rejectWithValue }
  ) => {
    axios
      .post("/crypto/create", values)
      .then((data: unknown) => {
        const cryptoData = data as CryptoCurrency;
        if (onSuccess) onSuccess(cryptoData);
        return cryptoData;
      })
      .catch((error) => {
        if (onFailure) onFailure(error);
        return rejectWithValue({ error });
      });
  }
);

export const getAllNetworks = createAsyncThunk(
  "network/all",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: NetworkResponseValue = await axios.get("/networks");

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);

export const getAllCurrencies = createAsyncThunk(
  "currency/all",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const data: CurrencyValue[] = await axios.get("/currency");

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
export const getAllGiftcards = createAsyncThunk(
  "giftcard/all",
  async ({ onSuccess, onFailure }: ReduxAction, { rejectWithValue }) => {
    try {
      const { data }: GiftcardResponseValue = await axios.get("/giftCards");

      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      if (onFailure) onFailure(error);
      return rejectWithValue({ error });
    }
  }
);
