import classNames from "classnames";
import { useField } from "formik";
import React, { ReactNode } from "react";
import CurrencyInput from "react-currency-input-field";

interface CurrencyInputProps {
  name: string;
  label?: string | ReactNode;
  placeholder: string;
  extraStyles?: string;
  className?: string;
  parentClassName?: string;
  labelClassName?: string;
  defaultValue?: number;
  decimal?: number;
  disabled?: boolean;
  prefix?: string;
}
const CurrencyInputField = ({
  name,
  label,
  placeholder,
  extraStyles,
  parentClassName,
  labelClassName,
  className,
  defaultValue = 0,
  decimal = 2,
  prefix = "₦",
  disabled = false,
}: CurrencyInputProps) => {
  const [, meta, helpers] = useField(name);
  return (
    <div className={classNames(parentClassName)}>
      <label className={classNames("text-sm font-semibold", labelClassName)}>
        {label}
      </label>
      <div
        className={classNames(
          "inline-flex items-center mt-1 space-x-2 w-full rounded-md focus-within:border-blue-500",
          {
            "bg-white border border-gray-300": !disabled,
            "bg-[#FAFAFA] border-[#FAFAFA]": disabled,
          },
          extraStyles
        )}
      >
        <CurrencyInput
          name={name}
          placeholder={placeholder}
          defaultValue={meta.value || defaultValue}
          decimalsLimit={decimal}
          prefix={prefix}
          className={classNames(
            "p-3 peer focus:outline-none text-sm w-full text-gray-900 disabled:bg-[#FAFAFA] disabled:text-[#979797]",
            className
          )}
          onFocus={() => {
            helpers.setTouched(true);
          }}
          onValueChange={(value) => {
            if (typeof value !== "undefined") helpers.setValue(value);
          }}
        />
      </div>

      {meta.touched && meta.error ? (
        <p className="text-red-600">{meta.error}</p>
      ) : null}
    </div>
  );
};

export default CurrencyInputField;
